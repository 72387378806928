import blogsApi from "@/api/blogsApi";
import constants from "@/utils/constants";
import keysReactQuery from "@/utils/keys";
import { useQuery } from "@tanstack/react-query";

const MINUTES_STALE_TIME = 0.1;

export default function useBlogsQuery(
  pageSize: string = String(constants.PAGE_SIZE_BLOGS),
  page: string
) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: [keysReactQuery.BLOGS, page],
    queryFn: () => blogsApi.getAll(pageSize, page),
    refetchOnWindowFocus: false,
    staleTime: MINUTES_STALE_TIME * 60 * 1000,
  });

  const blogList = data?.data?.data || [];
  const informationPagination = data?.data?.meta?.pagination;

  return { data, isLoading, isFetched, blogList, informationPagination };
}

export const useFrequentlyQuestions = () => {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["frequentlyQuestions"],
    queryFn: () => blogsApi.getFrequentlyQuestion(),
  });

  const questionList = data?.data?.data || [];
  const informationPagination = data?.data?.meta?.pagination;

  return { data, isLoading, isFetched, questionList, informationPagination };
};
