import { Breadcrumb as BreadcrumbAntd, Divider } from "antd";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
interface BreadcrumbItem {
  href?: string;
  title: string;
}
interface BreadcrumbProps {
  items: BreadcrumbItem[];
  className?: string;
}

const BreadcrumbAntdStyled = styled(BreadcrumbAntd)`
  &.ant-breadcrumb {
    ol {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      li {
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        span {
          a {
            display: inline;
          }
        }
      }
    }
  }
`;

const Breadcrumb = (props: BreadcrumbProps) => {
  const { items, className } = props;

  const breadcumbItems = items.map((item, index) => {
    if (index === items.length - 1) {
      return {
        title: (
          <span className="text-[13px] md:text-[13px] text-[#818080]">
            {item.title}
          </span>
        ),
      };
    }

    return {
      title: item.href ? (
        <Link
          to={item.href}
          key={index}
          className="text-[13px] md:text-[13px] text-[#000000]"
        >
          {item.title}
        </Link>
      ) : (
        <span className="text-[13px] md:text-[13px] text-[#000000]">
          {item.title}
        </span>
      ),
    };
  });

  return (
    <div className="w-full bg-[#F7F7F7] md:px-auto">
      <Divider className="h-[1px] my-0 bg-[#E2DCCE] md:hidden block" />
      <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] justify-center md:py-[8px] w-full mx-auto">
        <BreadcrumbAntdStyled
          items={breadcumbItems}
          className={clsx(
            "justify-center md:container max-w-[420px] sm:max-w-[720px] md:max-w-[1120px] md:mx-auto md:relative text-ellipsis line-clamp-1",
            className
          )}
          separator={"|"}
        />
      </div>
    </div>
  );
};

export default Breadcrumb;
