import productApi from "@/api/productApi";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import { useQueries } from "@tanstack/react-query";

const MINUTES_TIME_STALE = 10;

export default function useProductsQuery(searchParams?: string) {
  const [searchedProductsRes, productsRes] = useQueries({
    queries: [
      {
        queryKey: ["searchedProductsRes", searchParams],
        queryFn: () => productApi.getAll(searchParams),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
      {
        queryKey: ["productsRes", searchParams],
        queryFn: () => productApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
    ],
  });

  const products: BaseData<ProductsResponse>[] =
    searchedProductsRes?.data?.data?.data || [];
  const informationPagination = searchedProductsRes?.data?.data?.meta?.pagination;
  return {
    informationPagination,
    data: searchedProductsRes?.data,
    products: products || [],
    isLoading: searchedProductsRes?.isLoading,
    isFetched: searchedProductsRes?.isFetched,
    allProducts: productsRes?.data?.data?.data || [],
  };
}
