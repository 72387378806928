import "dayjs/locale/vi";
import AppRouter from "./routers/AppRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
export default function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div style={{ maxWidth: "100vw", height: "100vh" }}>
          <AppRouter />
        </div>
      </QueryClientProvider>
    </>
  );
}
