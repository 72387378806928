export const ingredientOrder: Record<number | string, number[]> = {

  // 3: thương hiệu
  // 4: loại sản phẩm
  // 5: dung tích
  // 6: thành phần chính
  // 7: công dụng
  // 8: lưu ý sử dụng
  // 9: hạn sử dụng
  // 10: sản xuất tại
  // 11: loại da phù hợp
  // 12: Số sản phẩm
  // 13: chỉ số chống nắng
  // 14: quy cách đóng gói
  // 15: tên hãng
  // 16: trọng lượng
  // 17: phụ kiện đi kèm

  // Mặc định
  "-1": [3, 5, 6, 4, 13, 7, 11, 9, 8, 10],

  // "Serum, tinh chất"
  36: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Phấn khử mùi
  43: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Bột đánh răng
  31: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Phấn gội khô
  42: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // xịt tạo kiểu
  44: [3, 5, 6, 4, 7, 9, 8, 10],

  // Sửa rửa mặt, tẩy trang
  45: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Toner, xịt khoáng
  39: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Thanh lăn, dán mụn
  38: [3, 14, 6, 4, 7, 11, 9, 8, 10],

  // Tẩy tế bào chết, mặt nạ
  37: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Xịt dưỡng
  34: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Kem dưỡng
  33: [3, 5, 6, 4, 7, 11, 9, 8, 10],

  // Kem chống nắng
  32: [3, 5, 6, 4, 13, 7, 11, 9, 8, 10],
}