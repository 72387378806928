import agencyApi from "@/api/agency";
import images from "@/assets/images";
import svgs from "@/assets/svgs";
import NewInputField from "@/components/common/Formfield/NewInputField";
import NewRadioField from "@/components/common/Formfield/NewRadioField";
import { agencyRequestType } from "@/type/request/agency";
import { formValidation } from "@/utils/formValidation";
import routes from "@/utils/routes";
import clsx from "clsx";
import { useState } from "react";
import { SubmitHandler, useForm, useFormState } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

export default function PartnerRegister() {
  const navigate = useNavigate();
  const [showTime, setShowTime] = useState<boolean>(false);
  const [contactTime, setContactTime] = useState<string>("");
  const arrayTime = [
    {
      label: "8:00- 11:00",
      key: 1,
    },
    {
      label: "12:00- 13:00",
      key: 2,
    },
    {
      label: "13:00- 16:00",
      key: 1,
    },
    {
      label: "16:00- 19:00",
      key: 1,
    },
    {
      label: "19:00- 22:00",
      key: 1,
    },
  ];
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<agencyRequestType>({
    defaultValues: {
      email: "",
      fullname: "",
      name: "",
      warehouse_address: "",
      note: contactTime || "",
    },
  });

  const { isValid } = useFormState({ control })

  const onSubmit: SubmitHandler<agencyRequestType> = async (formData) => {
    const newData = {
      email: formData?.email,
      fullname: formData?.fullname,
      name: formData?.name,
      warehouse_address: formData?.warehouse_address,
      note: contactTime || "",
    };
    await agencyApi
      .post(newData)
      .then((res) => {
        if (res.data) {
          toast.success("Đã ghi lại thông tin của bạn");
          reset();
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  const handleChooseContactTime = (time: string) => {
    setContactTime(time);
    setShowTime(false);
  };
  return (
    <div className="grid md:grid-cols-2 gap-[8px]">
      <div className="relative md:hidden">
        <div className="absolute top-0 bg-[#0000004D] h-[214px] flex items-center justify-center">
          <h2 className="text-[36px] font-[600] text-center text-white m-0">
            Trở thành đối tác CERANEE
          </h2>
        </div>
        <img
          src={images.partnerRegister}
          alt="image"
          className="max-h-[214px] w-full object-cover"
        />
      </div>
      <div className="p-[48px_16px] md:p-[94px_160px] gap-[64px]">
        <h2 className="text-[36px] font-[600] text-center text-[#101828] m-0 md:block hidden">
          Trở thành đối tác CERANEE
        </h2>

        <div className="md:mt-[32px]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-[24px]"
          >
            <NewInputField
              control={control}
              name="email"
              label="Email*"
              placeholder="Nhập email của bạn"
              rules={formValidation.email}
              errors={errors.email}
            />
            <NewInputField
              control={control}
              name="fullname"
              label="Họ và tên bạn *"
              placeholder="Nhập họ và tên của bạn"
              rules={formValidation.fullName}
              errors={errors.fullname}
            />
            <NewInputField
              control={control}
              name="name"
              label="Tên đại lý"
              placeholder="Nhập tên đại lý"
            />
            <NewInputField
              control={control}
              name="warehouse_address"
              label="Địa chỉ kho"
              placeholder="Nhập địa chỉ kho"
            />
            <div>
              <label className="font-[500] text-[16px] leading-[20px] text-[#484848]">
                Khung giờ liên lạc
              </label>

              <div className="flex items-center justify-between border-b-[1px] border-b-black p-[6px_4px_6px_14px]">
                <label
                  className={`text-[14px] ${contactTime ? "text-black" : "text-[#808080]"
                    } `}
                >
                  {contactTime || `13:00 - 16:00`}
                </label>
                <div
                  className="p-[3px] hover:bg-[#E6E6E6] rounded-full flex items-center justify-center cursor-pointer duration-300"
                  onClick={() => setShowTime(!showTime)}
                >
                  <img
                    src={svgs.chevron}
                    alt="icon"
                    className={`size-[32px] ${showTime ? "rotate-180" : ""
                      } duration-300 transition ease-in-out`}
                  />
                </div>
              </div>

              {showTime && (
                <ul
                  className={`list-none mt-[24px] flex flex-col gap-[24px] ml-[14px] ${showTime ? "animate-slide-down" : "animate-slide-up"
                    } `}
                >
                  {arrayTime.map((item) => (
                    <li
                      className={`text-[14px] hover:text-[#FF9900] hover:font-[600] cursor-pointer duration-200 ${contactTime === item?.label
                        ? "text-[#FF9900] font-[600]"
                        : ""
                        }`}
                      key={item?.key}
                      onClick={() => handleChooseContactTime(item?.label)}
                    >
                      {item?.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <NewRadioField
              name="policy"
              className="mt-[32px]"
              control={control}
              options={[
                {
                  value: 1,
                  label: (
                    <p className="text-[#000000] text-[14px] md:text-[16px] font-[400]">
                      Tôi đã đọc và đồng ý với{" "}
                      <Link
                        replace
                        to={`/${routes.SUPPORT}/${routes.PRIVACY_POLICY}`}
                      >
                        <span className="text-[14px] md:text-[16px] font-[400] text-[#FF9900]">
                          chính sách bảo mật
                        </span>
                      </Link>
                    </p>
                  ),
                },
              ]}
              rules={formValidation.policy}
              errors={errors.policy}
            />

            <button className={clsx("p-[12px_20px] rounded-[24px] text-[14px] font-[600]",
              isValid ? "bg-black text-[#FFFFFF] hover:text-[#FF9900]" : "bg-[#E6E6E6] text-[#818080] pointer-events-none"
            )}>
              Gửi
            </button>
          </form>
          <div className="mt-[32px] flex items-center flex-col gap-[16px]">
            <p className="text-[14px]">
              Bạn đã để lại thông tin nhưng chưa được liên hệ?
            </p>
            <p
              className="text-[14px] text-[#FF9900] font-[600] cursor-pointer p-[12px_20px]"
              onClick={() => {
                navigate(`${routes.HOME}${routes.SUPPORT}`);
              }}
            >
              Hỗ trợ
            </p>
          </div>
        </div>
      </div>
      <div className="md:block hidden">
        <img
          src={images.partnerRegister}
          alt=""
          className={`size-full object-cover ${showTime ? "transition ease-in-out" : ""
            }`}
        />
      </div>
    </div>
  );
}
