import { CartState, ICartProduct, IICartStateRecoil } from "@/recoil/cartState";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import cartSelector from "@/recoil/selectors/cartSelector";
import { Link, useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import { Divider, Empty, Popover } from "antd";
import images from "@/assets/images";
import OrderCart from "../OrderCart";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import BillText from "../BillText";
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import clsx from "clsx";
import VoucherPopoverContent from "@/components/pages/Cart/TotalCost/VoucherPopoverContent";
import { BaseData } from "@/type/base/baseData";
import { UserVouchers } from "@/type/response/profile";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import { useQueryUserVouchers } from "@/hooks/ReactQuery/profile.query";
import toast from "react-hot-toast";
import Scrollbars from "rc-scrollbars";
import selectedVouchersState from "@/recoil/selectedVoucherState";
import { useFlashSaleQueryData } from "@/hooks/ReactQuery/home.flashSale.query";
import { updateCartWithFlashSale } from "@/utils/updateCartFlashSale";
interface CartHeaderProps {
  onClose: () => void;
}
const CartHeader = ({ onClose }: CartHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { totalNumberProducts, totalPrice, totalThrifty } =
    useRecoilValue(cartSelector);
  const [cart, setCart] = useRecoilState<IICartStateRecoil>(CartState);
  const [listProductOutStock, setListProductOutStock] = useState<number[]>([]);

  const handleOutStock = (id: number) => {
    setListProductOutStock((prev) => [...prev, id]);
  };

  const handleRemoveOutStock = (id: number) => {
    const newList = listProductOutStock?.filter((item) => item !== id);
    setListProductOutStock(newList);
  };
  const { flashSale, productsFlashSale, isLoading } = useFlashSaleQueryData();

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedCart = updateCartWithFlashSale(flashSale, productsFlashSale);
      setCart((prev) => ({ ...prev, cartProducts: updatedCart }));
    }, 5000); // update every 5s

    return () => clearInterval(interval);
  }, [flashSale?.attributes?.endTime, isLoading]);

  //voucher
  const { allVouchers } = useQueryUserVouchers();
  const myVoucherList = allVouchers.filter(
    (item) => item?.attributes?.status === "UNUSED"
  );
  const [voucher, setVoucher] = useState<IVoucherState[]>([]);

  const [isShowTextVoucher, setIsShowTextVoucher] = useState<boolean>(false);
  const onChangeVoucher = (selected: BaseData<UserVouchers> | null) => {
    if (selected === null) {
      return;
    }

    const {
      amountDecrease,
      code,
      percentDecrease,
      status,
      title,
      minimumOrderAmount,
      condition,
    } = selected.attributes.voucher.data.attributes;

    const newVoucher: IVoucherState = {
      voucherId: selected.id,
      amountDecrease: amountDecrease,
      code: code,
      percentDecrease: percentDecrease,
      status: status,
      title: title,
      minimumOrderAmount: minimumOrderAmount,
      condition: condition,
    };

    setIsShowTextVoucher(true);

    setVoucher((prev) => {
      const hasOrVoucher = prev.some((voucher) => voucher.condition === "OR");

      if (condition === "OR") {
        return [newVoucher];
      }

      if (hasOrVoucher && condition === "AND") {
        return [newVoucher];
      }

      const isVoucherExists = prev.some(
        (voucher) => voucher.voucherId === newVoucher.voucherId
      );

      if (isVoucherExists) {
        return prev.filter(
          (voucher) => voucher.voucherId !== newVoucher.voucherId
        );
      }

      return [...prev, newVoucher];
    });
  };

  const [isShowVoucher, setIsShowVoucher] = useState<boolean>(false);

  const [selectedVoucher] = useRecoilState<IVoucherState[]>(
    selectedVouchersState
  );
  const handleCancleVoucher = () => {
    setVoucher([]);
  };

  const submitVoucher = () => {
    // setSelectedVoucher(voucher);
    setIsShowVoucher(false);
    toast.success("Đã áp dụng voucher");
  };
  const totalAmountDecrease = selectedVoucher?.reduce((acc, item) => {
    const amount = item?.amountDecrease ? Number(item.amountDecrease) : 0;
    return acc + amount;
  }, 0);

  const totalMinOrder = selectedVoucher?.reduce((acc, item) => {
    const amount = item?.minimumOrderAmount
      ? Number(item.minimumOrderAmount)
      : 0;
    return acc + amount;
  }, 0);
  const showedTotalPrice =
    totalPrice - (totalMinOrder < totalPrice ? Number(totalAmountDecrease) : 0);
  return (
    <>
      <div className="w-full h-full flex flex-col justify-between">
        {cart.cartProducts.length > 0 ? (
          <>
            <div>
              <div className="w-full flex justify-start items-center gap-[32px] *:text-[16px] *:font-[500] *leading-[19.36px] *:text-black *:text-nowrap">
                <span className="w-[321px]">Sản phẩm</span>
                <span className="w-[77px]">Số lượng</span>
                <span className="w-[26px]">Giá</span>
              </div>
              {cart?.cartProducts?.length > 2 ? (
                <Scrollbars
                  style={{ maxWidth: "100%", height: "350px" }}
                  autoHide
                  renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical z-[9999]" />
                  )}
                >
                  <div className="mt-[24px] mr-[15px]">
                    {cart.cartProducts.map(
                      (item: ICartProduct, index: number) => (
                        <div key={index}>
                          <OrderCart
                            orderItem={item}
                            key={index}
                            handleOutStock={handleOutStock}
                            handleRemoveOutStock={handleRemoveOutStock}
                          />
                          {cart.cartProducts.length - 1 !== index && (
                            <div className="w-full my-[24px]">
                              <Divider className="h-[1px] bg-[#B0B0B0] m-0" />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </Scrollbars>
              ) : (
                <div className="mt-[24px] mr-[15px]">
                  {cart.cartProducts.map(
                    (item: ICartProduct, index: number) => (
                      <div key={index}>
                        <OrderCart
                          orderItem={item}
                          key={index}
                          handleOutStock={handleOutStock}
                          handleRemoveOutStock={handleRemoveOutStock}
                        />
                        {cart.cartProducts.length - 1 !== index && (
                          <div className="w-full my-[24px]">
                            <Divider className="h-[1px] bg-[#B0B0B0] m-0" />
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            {cart.cartProducts.length > 0 && (
              <div className="mt-[32px]">
                <Popover
                  content={
                    <VoucherPopoverContent
                      onChangeVoucher={onChangeVoucher}
                      voucher={voucher}
                      myVoucherList={myVoucherList}
                      isShowTextVoucher={isShowTextVoucher}
                      submitVoucher={submitVoucher}
                      totalPrice={totalPrice}
                      closePopUp={() => setIsShowVoucher(false)}
                      cancleVouchers={handleCancleVoucher}
                      onClose={onClose}
                    />
                  }
                  open={isShowVoucher}
                  onOpenChange={() => setIsShowVoucher(!isShowVoucher)}
                  arrow={false}
                  overlayClassName={"border border-[#C6C6C6]"}
                  overlayInnerStyle={{ padding: 0 }}
                  placement="topLeft"
                  className="max-h-[480px]"
                >
                  <div className="flex flex-col gap-[8px]">
                    <div className="flex items-center gap-4">
                      <div className="font-normal text-[14px] md:text-[16px] cursor-pointer">
                        Chọn voucher
                      </div>

                      <ChevronUpIcon
                        className={clsx(
                          "w-4 md:block hidden cursor-pointer transition-transform",
                          isShowVoucher && "rotate-180"
                        )}
                      />
                      <ChevronRightIcon className="w-4 md:hidden cursor-pointer" />
                    </div>
                    {selectedVoucher && totalMinOrder < totalPrice && (
                      <p className="text-[14px] font-[600] leading-[16.94px] text-[#FF9900]">
                        {selectedVoucher?.map((item) => item?.code).join(" , ")}
                      </p>
                    )}
                  </div>
                </Popover>
                <div className="flex flex-col gap-[16px] mt-[16px]">
                  <BillText
                    type="normal"
                    nameItem={t("total")}
                    valueItem={`${totalNumberProducts} sản phẩm`}
                  />
                  <BillText
                    type="normal"
                    nameItem="Tạm tính"
                    valueItem={formatPriceToVnd(
                      totalThrifty + totalPrice
                    ).toString()}
                  />
                  <BillText
                    type="discount"
                    nameItem="Giảm giá"
                    valueItem={formatPriceToVnd(totalThrifty).toString()}
                  />
                </div>
                <div className="flex flex-col gap-[16px] mt-[32px]">
                  <BillText
                    type="total-cart"
                    nameItem="Tổng thanh toán"
                    valueItem={formatPriceToVnd(showedTotalPrice).toString()}
                  />

                  <button
                    className={`p-[16px_28px] text-[16px] font-[500] leading-[19.36px] ${!listProductOutStock.length
                      ? "bg-black text-[#f0f0f0] hover:text-[#FF9900]"
                      : "bg-[#EDEDED] text-[#848484] cursor-not-allowed"
                      } duration-300 rounded-[24px]`}
                    onClick={() => {
                      onClose();
                      navigate(routes.CART);
                    }}
                    disabled={!!listProductOutStock.length}
                  >
                    Mua hàng
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Empty
            image={images.cartEmpty}
            imageStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            description={
              <div className="text-center py-2 my-8">
                <Link
                  to={"/"}
                  className="block w-full text-base underline text-piper-lolot text-center"
                >
                  {t("continueShopping")}
                </Link>
              </div>
            }
          />
        )}
      </div>
    </>
  );
};

export default CartHeader;
