import FormDeliveryInfo, {
  FormTypActions,
} from "@/components/common/FormDeliveryInfo";
import BasicModal from "@/components/common/Modal";
import DeliveryWrapper from "@/components/pages/Cart/NewDeliveryInformation/DeliveryWrapper";
import useUserAddress from "@/hooks/ReactQuery/userAdress.query";
import useWindowSize from "@/hooks/useWindowSize";
import userAddressState from "@/recoil/userAddressState";
import { UserProfileType } from "@/type/response/user";
import constants from "@/utils/constants";
import routes from "@/utils/routes";
import { Pagination, PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const AddressProfile = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const [addressData, setAddressData] = useRecoilState(userAddressState);
  const [openModalFormInfo, setOpenModalFormInfo] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dataProfile = localStorage.getItem(constants.PROFILE);

  const profile: UserProfileType = dataProfile ? JSON.parse(dataProfile) : {};
  const [openTime, setOpenTime] = useState<string>();
  const [typeAction, setTypeAction] = useState<FormTypActions>();
  const { address, isLoading, addressPagination } = useUserAddress(
    `filters[user]=${profile?.id}&pagination[page]=${currentPage}&pagination[pageSize]=5`
  );
  useEffect(() => {
    if (!address) return;

    const sortedAddresses = [...address].sort((a, b) => {
      if (a?.attributes?.isDefault) return -1;
      if (b?.attributes?.isDefault) return 1;
      return 0;
    });
    setAddressData(sortedAddresses);
  }, [isLoading, currentPage]);

  const handleAddNewAddress = () => {
    if (width >= 768) {
      setTypeAction("create");
      setOpenTime(new Date().toString());
      setOpenModalFormInfo(true);

      return;
    }
    navigate(routes.PROVIDE_ADDRESS, {
      state: {
        typeAction: "create",
        fromPage: `${routes.PROFILE}/${routes.ADDRESS_PROFILE}`,
      },
    });
  };
  const handleCancelFormInfo = () => {
    setOpenModalFormInfo(false);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="w-full py-[24px] md:p-[24px_0_24px_24px]">
        <p className="hidden md:block text-[20px] font-[500] leading-[24.2px]">
          Địa chỉ
        </p>
        <div className="py-0 md:py-[32px] mt-0 md:mt-[12px] min-h-[500px]">
          <DeliveryWrapper
            listItem={addressData}
            handleAddNewAddress={handleAddNewAddress}
            hasScrollbar={false}
            fromPage={location.pathname}
            isCheckout={false}
          />
        </div>
        {addressPagination && (
          <div className="flex justify-center items-center my-[16px]">
            <Pagination
              current={currentPage || 1}
              total={
                (addressPagination?.pageCount || 1) *
                (addressPagination?.pageSize || 5)
              }
              pageSize={5}
              onChange={onChange}
            />
          </div>
        )}
      </div>
      {width >= 768 && (
        <BasicModal
          rootClassName="formDeliveryInfo hidden md:block"
          className="md:min-w-[70%] md:w-[70%] lg:min-w-[640px] lg:w-[640px]"
          isModalOpen={openModalFormInfo}
          handleCancel={handleCancelFormInfo}
          handleOk={() => {}}
          footer={null}
          maskClosable
        >
          <FormDeliveryInfo
            handleCancel={handleCancelFormInfo}
            typeAction={typeAction}
            optionTime={openTime}
          />
        </BasicModal>
      )}
    </>
  );
};

export default AddressProfile;
