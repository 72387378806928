import productApi from "@/api/productApi";
import { useQuery } from "@tanstack/react-query";

const useProductPageQuery = () => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["productPage"],
    queryFn: () => productApi.productPage(),
    refetchInterval: false,
  });

  return {
    data: data?.data?.data || null,
    isLoading,
    isFetching,
    refetch,
  };
};
export default useProductPageQuery;
