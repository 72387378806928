const routes = {
  HOME: "/",
  LOGIN: "/login",
  PRODUCT: "/products",
  BLOGS: "/blogs",
  BLOGS_QA: "/blogs-qa",
  QUESTIONS: "/questions",
  ROUTINE: "/routine",
  BRAND: "/brand",
  PARTNER: "/partner",
  REGISTER: "/register",
  CART: "/cart",
  ORDER_SUCCESS: "/order-success",
  ORDER_FAIL: "/order-fail",
  NOT_FOUND: "/404",
  PROFILE: "/profile",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  PROFILE_MEMBERSHIP: "membership",
  PROFILE_INFO: "updateinfo",
  VOUCHERS: "vouchers",
  VOUCHERSCART: "vouchers-cart",
  WAITING_ORDERS: "waiting-orders",
  ORDERS: "orders",
  DELIVERING_ORDERS: "delivering-orders",
  RECEIVED_ORDERS: "received-orders",
  ORDERS_HISTORY: "orders-history",
  SUPPORT: "support",
  VNPAY_HELP: "vnpay-help",
  FORGOT_PASSWORD_HELP: "forgot-pw-help",
  RETURN_POLICY: "return-policy",
  PRIVACY_POLICY: "privacy-policy",
  SEARCH_RESULT: "search-result",
  SUCCESS: "/success",
  VERIFY_OTP: "/verify-otp",
  CHAT: "/chat",
  PROVIDE_ADDRESS: "/provide-address",
  CHANGE_ADDRESS: "/change-address",
  FEEDBACKS: "/feedbacks",
  COMMENTS: "/comments",
  ABOUT_US: "/about-us",
  ORDER_DETAIL: "/order-detail",
  FEEDBACK_ORDER: "/feedback-order",
  ADDRESS_PROFILE: "address",
  LOG_OUT: "/log-out",
};
export default routes;
