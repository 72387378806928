import { SubmitHandler, useForm } from "react-hook-form";
import NewInputField from "../Formfield/NewInputField";
import NewSelectField from "../Formfield/NewSelectField";
import NewRadioField from "../Formfield/NewRadioField";
import { typeValueAddress, UserAddressType } from "@/utils/AddressType";
import NewButtonComponent from "../NewButton";
import { BaseData } from "@/type/base/baseData";
import { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import {
  DistrictGHNType,
  ProvinceGHNType,
  WardGHNType,
} from "@/type/response/GHN";
import { formValidation } from "@/utils/formValidation";
import { UserAddressParamsType } from "@/type/request/userAddress";
import userAddressApi from "@/api/userAddressApi";
import constants from "@/utils/constants";
import { UserProfileType } from "@/type/response/user";
import { useRecoilState, useSetRecoilState } from "recoil";
import userAddressState, {
  checkUpdateAddress,
  maskCloseableFormAddressState,
  selectedAddressState,
} from "@/recoil/userAddressState";
import toast from "react-hot-toast";
import useWindowSize from "@/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import GHNApi from "@/api/GHNApi";
import routes from "@/utils/routes";
import { isAxiosError } from "axios";
interface FormUserAddressParams {
  full_name: string;
  phone: string;
  email: string;
  city: string;
  district: string;
  ward?: string;
  house_street: string;
  type: number;
}
export type FormTypActions = "create" | "edit" | undefined;
interface IFormDeliveryInfo {
  dataDeliveryInfo?: BaseData<UserAddressType>;
  typeAction: FormTypActions;
  handleCancel: () => void;
  fromPage?: string;
  optionTime?: string;
}
const getKeyFromValue = (value?: string) => {
  if (!value) return 2;
  const entries = Object.entries(typeValueAddress);
  const found = entries.find(([, v]) => v === value);
  return found ? parseInt(found[0], 10) : 2;
};

const FormDeliveryInfo = (props: IFormDeliveryInfo) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { dataDeliveryInfo, typeAction, handleCancel, fromPage, optionTime } =
    props;
  const [addressData, setAddressData] = useRecoilState(userAddressState);
  const [checkUpdateAddressData, setUpdateAddressData] =
    useRecoilState(checkUpdateAddress);
  const setSelectedAddress = useSetRecoilState(selectedAddressState);
  const [maskCloseableForm] = useRecoilState(maskCloseableFormAddressState);
  const [provinceData, setProvinceData] = useState<DefaultOptionType[]>();
  const [districtData, setDistrictData] = useState<DefaultOptionType[]>();
  const [wardData, setWardData] = useState<DefaultOptionType[]>();
  const [isWard, setIsWard] = useState<boolean>(true);
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile: UserProfileType = dataProfile ? JSON.parse(dataProfile) : {};

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<FormUserAddressParams>({
    // defaultValues:
    //   typeAction === "edit"
    //     ?
    //     {
    //         full_name: dataDeliveryInfo?.attributes.fullName,
    //         phone: dataDeliveryInfo?.attributes.phone,
    //         email: dataDeliveryInfo?.attributes.email,
    //         city: `${dataDeliveryInfo?.attributes.address.provinceCity.id}-${dataDeliveryInfo?.attributes.address.provinceCity.name}`,
    //         district: `${dataDeliveryInfo?.attributes.address.district.id}-${dataDeliveryInfo?.attributes.address.district.name}`,
    //         ward: dataDeliveryInfo?.attributes.address.ward.code
    //           ? `${dataDeliveryInfo?.attributes.address.ward.code}-${dataDeliveryInfo?.attributes.address.ward.name}`
    //           : dataDeliveryInfo?.attributes.address.ward.name,
    //         house_street: dataDeliveryInfo?.attributes.address.street,
    //         type: dataDeliveryInfo?.attributes.isDefault
    //           ? 1
    //           : getKeyFromValue(dataDeliveryInfo?.attributes.type),
    //       }
    //     : {
    //         full_name: profile?.fullName,
    //         email: profile?.email,
    //         phone: profile?.phone,
    //         type: 1,
    //         ward: undefined,
    //         district: undefined,
    //         city: undefined,
    //         house_street: undefined,
    //       },
    mode: "onChange",
  });

  const [city, district, ward] = watch(["city", "district", "ward"]);

  useEffect(() => {
    if (typeAction === "edit") {
      reset({
        full_name: dataDeliveryInfo?.attributes.fullName,
        phone: dataDeliveryInfo?.attributes.phone,
        email: dataDeliveryInfo?.attributes.email,
        city: `${dataDeliveryInfo?.attributes.address.provinceCity.id}-${dataDeliveryInfo?.attributes.address.provinceCity.name}`,
        district: `${dataDeliveryInfo?.attributes.address.district.id}-${dataDeliveryInfo?.attributes.address.district.name}`,
        ward: dataDeliveryInfo?.attributes.address.ward.code
          ? `${dataDeliveryInfo?.attributes.address.ward.code}-${dataDeliveryInfo?.attributes.address.ward.name}`
          : dataDeliveryInfo?.attributes.address.ward.name,
        house_street: dataDeliveryInfo?.attributes.address.street,
        type: dataDeliveryInfo?.attributes.isDefault
          ? 1
          : getKeyFromValue(dataDeliveryInfo?.attributes.type),
      });
    } else {
      reset({
        full_name: profile?.fullName,
        email: profile?.email,
        phone: profile?.phone,
        type: 1,
        ward: undefined,
        district: undefined,
        city: undefined,
        house_street: undefined,
      });
    }
  }, [dataDeliveryInfo?.id, checkUpdateAddressData, optionTime]);

  useEffect(() => {
    reset();
  }, [maskCloseableForm]);

  useEffect(() => {
    const fetchData = async () => {
      const provinceRes = await GHNApi.getProvinceGHN();
      setProvinceData(
        provinceRes?.data?.data?.map((item: ProvinceGHNType) => ({
          label: item.ProvinceName,
          value: `${item.ProvinceID}-${item.ProvinceName}`,
        }))
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!city) return;
      const splitCity = city.split("-");
      const districtRes = await GHNApi.getDistrictGHN(splitCity[0]);
      setDistrictData(
        districtRes?.data?.data
          ?.map((item: DistrictGHNType) => ({
            label: item.DistrictName,
            value: `${item.DistrictID}-${item.DistrictName}`,
          }))
          ?.slice(1)
      );

      setValue(
        "district",
        `${districtRes?.data?.data[1].DistrictID}-${districtRes?.data?.data[1].DistrictName}`
      );
    };
    fetchData();
  }, [city]);

  useEffect(() => {
    const fetchData = async () => {
      if (!district && !city) return;

      const splitDistrict = district.split("-");

      const wardRes = await GHNApi.getWardGHN(splitDistrict[0]);

      if (!wardRes?.data?.data) {
        setIsWard(false);
        setValue("ward", undefined);
        return;
      }

      setWardData(
        wardRes?.data?.data?.map((item: WardGHNType) => ({
          label: item.WardName,
          value: `${item.WardCode}-${item.WardName}`,
        }))
      );

      setIsWard(true);
      setValue(
        "ward",
        `${wardRes?.data?.data[0]?.WardCode}-${wardRes?.data?.data[0]?.WardName}`
      );
    };
    fetchData();
  }, [district]);

  const handleCreateUpdateData = (
    newAddress: BaseData<UserAddressType>,
    type: number
  ) => {
    if (type === 1) {
      userAddressApi.setUserAddressDefault(newAddress.id);

      const updateAddress = addressData?.map((item) => {
        if (item.attributes.isDefault === true) {
          return {
            id: item.id,
            attributes: {
              ...item.attributes,
              isDefault: false,
            },
          };
        }
        return item;
      });

      const formatNewAddress = {
        ...newAddress,
        attributes: {
          ...newAddress.attributes,
          isDefault: true,
        },
      };
      setAddressData([formatNewAddress, ...updateAddress]);

      setSelectedAddress(formatNewAddress);
      toast.success("Thêm địa chỉ mới thành công");

      reset({
        full_name: profile?.fullName,
        email: profile?.email,
        phone: profile?.phone,
        type: 1,
        ward: undefined,
        district: undefined,
        city: undefined,
        house_street: undefined,
      });
      if (width >= 768) {
        handleCancel();
      } else {
        navigate(fromPage || "/", {
          state: {
            isCheckout: fromPage === routes.CHANGE_ADDRESS,
          },
        });
      }

      setUpdateAddressData(new Date().toString());
    } else {
      const updateAddress = [
        ...addressData.slice(0, 1),
        newAddress,
        ...addressData.slice(1),
      ];

      setAddressData(updateAddress);
      toast.success("Thêm địa chỉ mới thành công");
      reset({
        full_name: profile?.fullName,
        email: profile?.email,
        phone: profile?.phone,
        type: 1,
        ward: undefined,
        district: undefined,
        city: undefined,
        house_street: undefined,
      });
      if (width >= 768) {
        handleCancel();
      } else {
        navigate(fromPage || "/", {
          state: {
            isCheckout: fromPage === routes.CHANGE_ADDRESS,
          },
        });
      }
      setUpdateAddressData(new Date().toString());
    }

    setDistrictData(undefined);
    setWardData(undefined);
  };

  const handleEditUpdateData = (
    newAddress: BaseData<UserAddressType>,
    type: number
  ) => {
    if (type === 1) {
      userAddressApi.setUserAddressDefault(newAddress.id);

      const updateAddress = addressData
        .map((item: BaseData<UserAddressType>) => {
          if (item.attributes.isDefault === true)
            return {
              id: item.id,
              attributes: {
                ...item.attributes,
                isDefault: false,
              },
            };

          return item;
        })
        .filter((item) => item.id !== newAddress.id);

      const formatNewAddress = {
        ...newAddress,
        attributes: {
          ...newAddress.attributes,
          isDefault: true,
        },
      };
      setAddressData([
        {
          id: newAddress.id,
          attributes: {
            ...newAddress.attributes,
            isDefault: true,
          },
        },
        ...updateAddress,
      ]);

      setSelectedAddress(formatNewAddress);
      toast.success("Cập nhật địa chỉ thành công");

      if (width >= 768) {
        handleCancel();
      } else {
        navigate(fromPage || "/", {
          state: {
            isCheckout: fromPage === routes.CHANGE_ADDRESS,
          },
        });
      }

      reset();
      setUpdateAddressData(new Date().toString());
    } else {
      const updateAddress = addressData.map(
        (item: BaseData<UserAddressType>) => {
          if (item.id === newAddress.id) return newAddress;
          return item;
        }
      );

      setAddressData(updateAddress);

      toast.success("Cập nhật địa chỉ thành công");

      if (width >= 768) {
        handleCancel();
      } else {
        navigate(fromPage || "/", {
          state: {
            isCheckout: fromPage === routes.CHANGE_ADDRESS,
          },
        });
      }

      reset();
      setUpdateAddressData(new Date().toString());
    }
    setDistrictData(undefined);
    setWardData(undefined);
  };

  const onSubmit: SubmitHandler<FormUserAddressParams> = async (data) => {
    const {
      full_name,
      email,
      phone,
      city,
      district,
      ward,
      house_street,
      type,
    } = data;

    if (!profile) return;

    const splitCity = city.split("-");
    const splitDistrict = district.split("-");
    const spiltWard = ward?.split("-");
    // const [house, ...restStreet] = house_street.split(",");

    const params: UserAddressParamsType =
      type === 1
        ? {
            full_name,
            email,
            phone,
            user: profile.id,
            address: {
              province_city: {
                id: Number(splitCity[0]),
                name: splitCity[1],
              },
              district: {
                id: Number(splitDistrict[0]),
                name: splitDistrict[1],
              },
              ward: {
                code: isWard && spiltWard ? spiltWard[0] : null,
                name: isWard && spiltWard ? spiltWard[1] : ward,
              },
              street: house_street,
              house_number: "",
            },
          }
        : {
            full_name,
            email,
            phone,
            user: profile.id,
            type: typeValueAddress[type],
            address: {
              province_city: {
                id: Number(splitCity[0]),
                name: splitCity[1],
              },
              district: {
                id: Number(splitDistrict[0]),
                name: splitDistrict[1],
              },
              ward: {
                code: isWard && spiltWard ? spiltWard[0] : null,
                name: isWard && spiltWard ? spiltWard[1] : ward,
              },
              street: house_street,
              house_number: "",
            },
          };

    if (typeAction === "create") {
      try {
        const createRes = await userAddressApi.createUserAddress(params);

        const newAddress: BaseData<UserAddressType> = createRes?.data?.data;

        if (!newAddress) {
          toast.error("Tạo địa chỉ giao hàng thất bại");
          return;
        }

        toast.success("Tạo địa chỉ giao hàng thành công");
        handleCreateUpdateData(newAddress, type);
        // window.location.reload();
        return;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(
            `Tạo địa chỉ giao hàng thất: ${error.response?.data?.error.message} `
          );
        }
      }
    }
    if (typeAction === "edit") {
      if (!dataDeliveryInfo) return;

      const updateParams =
        type !== 1
          ? {
              ...params,
              is_default: false,
            }
          : params;
      try {
        const updateRes = await userAddressApi.updateUserAddress(
          dataDeliveryInfo?.id,
          updateParams
        );

        const newAddress: BaseData<UserAddressType> = updateRes.data.data;

        if (!newAddress) return;

        handleEditUpdateData(newAddress, type);
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(
            `CHỉnh sửa địa chỉ giao hàng thất: ${error.response?.data?.error.message} `
          );
        }
      }
    }
  };

  const handleCancelButton = () => {
    handleCancel();
    reset();
    setDistrictData(undefined);
    setWardData(undefined);
  };

  return (
    <div className="w-full bg-white py-[24px] md:py-0">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[32px]"
      >
        <div className="px-[16px] md:px-0">
          <h3
            className="text-black text-[16px] md:text-[20px] font-[600] md:font-[700] leading-[19.3px] md:leading-[24.px]
           m-[0px_0px_16px_0px] py-0 md:m-[0px_0px_24px_0px] lg:m-[0px_0px_32px_0px]"
          >
            Thông tin cá nhân
          </h3>
          <div className="flex flex-col gap-[24px] md:gap-[32px]">
            <NewInputField
              className="popup"
              control={control}
              name="full_name"
              placeholder="Nhập họ và tên"
              rules={formValidation.fullName}
              errors={errors.full_name}
            />
            <NewInputField
              className="popup"
              control={control}
              name="phone"
              placeholder="Nhập số điện thoại"
              rules={formValidation.phone}
              errors={errors.phone}
            />

            <NewInputField
              className="popup"
              control={control}
              name="email"
              placeholder="Nhập email"
              rules={formValidation.email}
              errors={errors.email}
            />
          </div>
        </div>

        <div className="px-[16px] md:px-0">
          <h3
            className="text-black text-[16px] md:text-[20px] font-[600] md:font-[700] leading-[19.3px] md:leading-[24.px]
           m-[0px_0px_16px_0px] py-0 md:m-[0px_0px_24px_0px] lg:m-[0px_0px_32px_0px]"
          >
            Thông tin giao hàng
          </h3>
          <div className="flex flex-col gap-[16px] md:gap-[24px] lg:gap-[32px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px] md:gap-[32px]">
              <NewSelectField
                className="popup"
                control={control}
                name="city"
                placeholder="Tỉnh/thành phố"
                options={provinceData}
                rules={formValidation.city}
                errors={errors.city}
              />
              <NewSelectField
                className="popup"
                control={control}
                name="district"
                placeholder={"Quận/huyện"}
                options={districtData}
                rules={formValidation.district}
                errors={errors.district}
                disabled={!city}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-[25%_70%] gap-[16px] md:gap-[24px] lg:gap-[32px]">
              {isWard ? (
                <NewSelectField
                  className="popup"
                  control={control}
                  name="ward"
                  placeholder="Xã/phường"
                  options={wardData}
                  rules={formValidation.ward}
                  errors={errors.ward}
                  disabled={!city || !district}
                />
              ) : (
                <NewInputField
                  className="popup"
                  control={control}
                  name="ward"
                  placeholder="Xã/phường"
                  rules={formValidation.ward}
                  errors={errors.ward}
                  disabled={!city || !district}
                />
              )}

              <NewInputField
                className="popup"
                control={control}
                name="house_street"
                placeholder="Địa chỉ chi tiết (số nhà, tên đường...)"
                rules={formValidation.house_street}
                errors={errors.house_street}
                disabled={!city || !district || !ward}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#F0F0F0] md:bg-white p-[24px_16px] md:p-0">
          <h3
            className="text-black text-[16px] md:text-[20px] font-[500] md:font-[700] leading-[19.36px] md:leading-[24.px] 
          m-[0px_0px_16px_0px] py-0 md:m-[0px_0px_24px_0px] lg:m-[0px_0px_32px_0px]"
          >
            Đặt làm địa chỉ:
          </h3>
          <NewRadioField
            className="flex flex-col gap-[16px]"
            control={control}
            name="type"
            rules={formValidation.address_type}
            errors={errors.type}
            options={[
              {
                label: typeValueAddress[1].toString(),
                value: 1,
              },
              {
                label: typeValueAddress[2].toString(),
                value: 2,
              },
              {
                label: typeValueAddress[3].toString(),
                value: 3,
              },
            ]}
          />
        </div>
        <div className="flex justify-between md:justify-end items-center gap-[16px] px-[16px] md:px-0">
          <NewButtonComponent
            onClick={handleCancelButton}
            text="Hủy"
            type="text"
            className="popup w-full lg:w-[117px] md:w-[112px] rounded-[24px] md:rounded-[8px] py-[12px] md:py-[10px]s"
          />
          <NewButtonComponent
            htmlType="submit"
            className="hidden lg:block popup rounded-[24px] md:rounded-[8px] w-full lg:w-[117px] md:w-fit py-[12px] md:py-[10px]"
            textClassName="color-[#F0F0F0]"
            text="Hoàn thành"
          />
          <NewButtonComponent
            htmlType="submit"
            className="block lg:hidden popup rounded-[24px] md:rounded-[8px] w-full lg:w-[117px] md:w-fit py-[12px] md:py-[10px]"
            textClassName="color-[#F0F0F0]"
            text="Lưu"
          />
        </div>
      </form>
    </div>
  );
};

export default FormDeliveryInfo;
