import BackToHeader from "@/components/common/BackToHeader";
import FormDeliveryInfo from "@/components/common/FormDeliveryInfo";
import routes from "@/utils/routes";
import { useLocation, useNavigate } from "react-router-dom";

const ProvideAddress = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    typeAction = "create",
    address,
    fromPage = routes.PROFILE_INFO,
  } = location.state || {};

  const handleCancel = () => {
    navigate(fromPage, {
      state: {
        isCheckout: fromPage === routes.CHANGE_ADDRESS,
      },
    });
  };
  const handleBackTo = () => {
    navigate(fromPage, {
      state: {
        isCheckout: fromPage === routes.CHANGE_ADDRESS,
      },
    });
  };

  const contentBackTo =
    fromPage === `${routes.PROFILE}/${routes.ADDRESS_PROFILE}`
      ? "Cá nhân"
      : typeAction === "create"
      ? "Thêm địa chỉ mới"
      : "Chỉnh sửa địa chỉ";

  return (
    <div className="bg-[#f7f7f7] pb-[5.24px]">
      <BackToHeader content={contentBackTo} onBackTo={handleBackTo} />
      <FormDeliveryInfo
        handleCancel={handleCancel}
        typeAction={typeAction}
        dataDeliveryInfo={address}
        fromPage={fromPage}
      />
    </div>
  );
};

export default ProvideAddress;
