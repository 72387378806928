import homeApi from "@/api/homeApi";
import {
  BannerBrand,
  BannerSale,
  Block,
  ParentQuickFilter,
} from "@/type/response/home";
import keysReactQuery from "@/utils/keys";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { filterProgrammes as filterProgrammesApi } from "@/api/filterProductApi";

// const STALE_MINUTES = 1;
// const REFETCH_MINUTES = 2;

export default function useHomeQueryData() {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 60 * 1000); // Update current time every minute

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [keysReactQuery.HOME],
    queryFn: homeApi.getAll,
    refetchInterval: false, // Disable the default refetch interval
    // staleTime: STALE_MINUTES * 60 * 1000,
    // refetchInterval: REFETCH_MINUTES * 60 * 1000,
    // refetchOnWindowFocus: true,
  });

  const { data: categoriesTreeData, refetch: refetchTree } = useQuery({
    queryKey: ["categoriesTree"],
    queryFn: homeApi.getCategoriesTree,
  });

  const { data: programmesData } = useQuery({
    queryKey: ["programmesHome"],
    queryFn: () => filterProgrammesApi.getAll("populate=deep,4"),
  });

  useEffect(() => {
    const hours = currentTime.hours();
    const minutes = currentTime.minutes();

    // Check if the current time matches any of the specified times
    if (
      (hours === 0 && minutes === 0) /* 0h - 9h */ ||
      (hours === 9 && minutes === 0) /* 9h - 13h */ ||
      (hours === 13 && minutes === 0) /* 13h - 15h */ ||
      (hours === 15 && minutes === 0) /* 15h - 17h */ ||
      (hours === 17 && minutes === 0) /* 17h - 19h */ ||
      (hours === 19 && minutes === 0) /* 19h - 21h */ ||
      (hours === 21 && minutes === 0) /* 21h - 0h */
    ) {
      // Refetch data at the specified times
      refetch();
      refetchTree();
    }
  }, [currentTime, refetch]);

  const attributes = data?.data?.data?.attributes;
  const KolData = attributes?.kOLs ?? [];
  const bannerSale: BannerSale[] = attributes?.bannerSale ?? [];
  const mobileBanner: BannerSale[] = attributes?.mobileBanner ?? [];
  const partners = attributes?.partners ?? [];
  const routineBlock = attributes?.routineBlock ?? [];
  const parentQuick: ParentQuickFilter =
    attributes?.parentQuickFilter as ParentQuickFilter;

  const bannerBrand: BannerBrand = attributes?.bannerBrandValue as BannerBrand;

  const categoriesTree = categoriesTreeData?.data;

  let firstTwoBlocks: Block[] = [];
  let moreBlocks: Block[] = [];
  let blockIsEmpty = false;
  const block = data?.data?.data?.attributes?.block ?? [];
  if (block.length > 2) {
    firstTwoBlocks = block.slice(1, 2);
    moreBlocks = block.slice(2);
  } else if (block.length > 0 && block.length <= 2) {
    firstTwoBlocks = [...block];
  } else {
    blockIsEmpty = true;
  }

  return {
    isLoading,
    isFetching,
    KolData,
    bannerSale,
    mobileBanner,
    parentQuick,
    bannerBrand,
    blocks: block,
    firstTwoBlocks,
    moreBlocks,
    blockIsEmpty,
    partners,
    categoriesTree,
    programmesData: programmesData?.data,
    routineBlock,
  };
}
