import userAddressApi from "@/api/userAddressApi";
import BackToHeader from "@/components/common/BackToHeader";
import DeliveryWrapper from "@/components/pages/Cart/NewDeliveryInformation/DeliveryWrapper";
import useWindowSize from "@/hooks/useWindowSize";
import userAddressState, {
  selectedAddressState,
} from "@/recoil/userAddressState";
import { UserProfileType } from "@/type/response/user";
import constants from "@/utils/constants";
import routes from "@/utils/routes";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const ChangeAddress = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const { isCheckout = false } = location.state || {};
  const navigate = useNavigate();
  const [addressData, setAddressData] = useRecoilState(userAddressState);
  const [selectedAddress, setSelectedAddress] =
    useRecoilState(selectedAddressState);
  useEffect(() => {
    const fetchData = async () => {
      const dataProfile = localStorage.getItem(constants.PROFILE);
      const profile: UserProfileType = dataProfile
        ? JSON.parse(dataProfile)
        : {};
      if (!profile) return;
      const userAddressRes = await userAddressApi.getAll(
        `filters[user]=${profile?.id}`
      );
      const userAddressData = userAddressRes.data.data;

      if (!userAddressData) return;

      const sortedAddresses = userAddressData.sort((a, b) => {
        if (a?.attributes?.isDefault) return -1;
        if (b?.attributes?.isDefault) return 1;
        return 0;
      });
      if (!selectedAddress) setSelectedAddress(sortedAddresses[0]);
      setAddressData(sortedAddresses);
    };

    fetchData();
  }, []);

  const handleAddNewAddress = () => {
    navigate(routes.PROVIDE_ADDRESS, {
      state: {
        typeAction: "create",
        fromPage: routes.CHANGE_ADDRESS,
      },
    });
  };

  useEffect(() => {
    if (width >= 768) {
      navigate(routes.CART);
    }
  }, [width]);

  const handleBackTo = () => {
    navigate(routes.CART);
  };
  return (
    <div className="bg-[#f7f7f7] pb-[5.24px]">
      <BackToHeader content={"Đổi địa chỉ"} onBackTo={handleBackTo} />
      <DeliveryWrapper
        listItem={addressData}
        handleAddNewAddress={handleAddNewAddress}
        fromPage={location.pathname}
        hasScrollbar={false}
        isCheckout={isCheckout}
      />
    </div>
  );
};

export default ChangeAddress;
