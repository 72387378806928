import { useEffect, useState } from "react";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import { BaseData } from "@/type/base/baseData";
import { UserVouchers, Voucher } from "@/type/response/profile";
import { formatMoney } from "@/utils/data";
import { Modal } from "antd";
import Scrollbars from "rc-scrollbars";
import { useRecoilState } from "recoil";
import selectedVouchersState from "@/recoil/selectedVoucherState";
import { formatDate } from "@/utils/formatDate";
import { getProfile } from "@/utils/getProfile";
import LoginModal from "@/components/common/LoginModal";

interface IVoucherPopoverContentProps {
  onChangeVoucher: (myVoucher: BaseData<UserVouchers> | null) => void;
  voucher: IVoucherState[];
  myVoucherList: BaseData<UserVouchers>[];
  isShowTextVoucher: boolean;
  submitVoucher: () => void;
  closePopUp: () => void;
  cancleVouchers: () => void;
  totalPrice: number;
  isNewPage?: boolean;
  onClose?: () => void;
}

interface RenderVoucherProps {
  vouchers: BaseData<UserVouchers>[];
  title: string;
}

const VoucherPopoverContent = (props: IVoucherPopoverContentProps) => {
  const {
    onChangeVoucher,
    // voucher,
    myVoucherList,
    submitVoucher,
    cancleVouchers,
    totalPrice,
    closePopUp,
    isNewPage = false,
    onClose,
  } = props;

  const [selectedVouchers, setSelectedVouchers] = useState<IVoucherState[]>([]);
  const profile = getProfile();
  const [selectedVoucher, setSelectedVoucher] = useRecoilState<IVoucherState[]>(
    selectedVouchersState
  );
  const [isVoucherSelected, setIsVoucherSelected] = useState<boolean>(false);

  useEffect(() => {
    if (selectedVoucher && selectedVoucher.length > 0) {
      setSelectedVouchers(selectedVoucher);
    }
  }, [selectedVoucher?.length]);
  useEffect(() => {
    const checkVoucher = () => {
      let totalMinimumOrderAmount = 0;

      if (selectedVouchers.length > 0) {
        if (selectedVouchers.length > 1) {
          totalMinimumOrderAmount = selectedVouchers.reduce((acc, item) => {
            return acc + Number(item?.minimumOrderAmount || 0);
          }, 0);
        } else {
          totalMinimumOrderAmount = Number(
            selectedVouchers[0]?.minimumOrderAmount || 0
          );
        }
      } else if (selectedVoucher?.length > 0) {
        if (selectedVoucher.length > 1) {
          totalMinimumOrderAmount = selectedVoucher.reduce((acc, item) => {
            return acc + Number(item?.minimumOrderAmount || 0);
          }, 0);
        } else {
          totalMinimumOrderAmount = Number(
            selectedVoucher[0]?.minimumOrderAmount || 0
          );
        }
      }

      if (totalMinimumOrderAmount < totalPrice) {
        setIsVoucherSelected(true);
      } else {
        setIsVoucherSelected(false);
      }
    };

    checkVoucher();
  }, [selectedVouchers?.length, selectedVoucher?.length, totalPrice]);

  const handleVoucherClick = (voucher: BaseData<UserVouchers>) => {
    const selectedVoucherNow: IVoucherState = {
      voucherId: voucher?.id,
      amountDecrease:
        voucher?.attributes?.voucher?.data?.attributes?.amountDecrease,
      code: voucher?.attributes?.voucher?.data?.attributes?.code,
      percentDecrease:
        voucher?.attributes?.voucher?.data?.attributes?.percentDecrease,
      status: voucher?.attributes?.voucher?.data?.attributes?.status,
      title: voucher?.attributes?.voucher?.data?.attributes?.title,
      minimumOrderAmount:
        voucher?.attributes?.voucher?.data?.attributes?.minimumOrderAmount,
      condition: voucher?.attributes?.voucher?.data?.attributes?.condition,
    };
    const isInSelectedVouchers = selectedVouchers.some(
      (v) => v.voucherId === selectedVoucherNow.voucherId
    );

    const isInSelectedVoucher = selectedVoucher.some(
      (v) => v.voucherId === selectedVoucherNow.voucherId
    );

    if (isInSelectedVouchers || isInSelectedVoucher) {
      setSelectedVouchers((prevVouchers) =>
        prevVouchers.filter((v) => v.voucherId !== selectedVoucherNow.voucherId)
      );
      setSelectedVoucher((prevSelected) =>
        prevSelected.filter((v) => v.voucherId !== selectedVoucherNow.voucherId)
      );
      onChangeVoucher(null);
      return;
    }

    if (selectedVouchers.length > 0 && selectedVouchers[0].condition === "OR") {
      setSelectedVouchers([]);
      setSelectedVoucher([]);
      onChangeVoucher(null);
      return;
    }

    if (selectedVoucherNow.condition === "OR") {
      setSelectedVouchers([selectedVoucherNow]);
      setSelectedVoucher([selectedVoucherNow]);
      onChangeVoucher(voucher);
    } else {
      setSelectedVouchers((prevVouchers) => [
        ...prevVouchers,
        selectedVoucherNow,
      ]);
      setSelectedVoucher((prevSelected) => [
        ...prevSelected,
        selectedVoucherNow,
      ]);
      onChangeVoucher(voucher);
    }
  };

  const handleSubmitVoucher = () => {
    submitVoucher();
  };
  const RenderVouchers = ({ vouchers, title }: RenderVoucherProps) => {
    const firstVoucher = selectedVouchers?.[0] || selectedVoucher?.[0];

    return (
      <>
        {vouchers.length > 0 && (
          <h3
            className={`${
              isNewPage
                ? "font-[600] text-[14px] leading-[19.6px] m-0"
                : "font-[600] text-[16px] leading-[22.4px] m-0"
            }`}
          >
            {title}
          </h3>
        )}
        {vouchers.length > 0 && (
          <div className="flex flex-col gap-[12px] my-[24px]">
            {vouchers.map((voucher, index) => {
              const isSelected =
                selectedVouchers?.find(
                  (item) =>
                    item?.code ===
                    voucher?.attributes?.voucher?.data?.attributes?.code
                ) ||
                selectedVoucher?.find(
                  (item) =>
                    item?.code ===
                    voucher?.attributes?.voucher?.data?.attributes?.code
                );

              const isFirstVoucherConditionAnd =
                firstVoucher?.condition === "AND";
              const isFirstVoucherConditionOr =
                firstVoucher?.condition === "OR";

              const isConditionAnd =
                voucher?.attributes?.voucher?.data?.attributes?.condition ===
                "AND";
              const isConditionOr =
                voucher?.attributes?.voucher?.data?.attributes?.condition ===
                  "OR" && isSelected;

              const opacityClass = (() => {
                if (isFirstVoucherConditionAnd) {
                  return isConditionAnd
                    ? "opacity-100 cursor-pointer"
                    : "bg-[#F0F0F0] text-[#808080] cursor-not-allowed pointer-events-none";
                } else if (isFirstVoucherConditionOr) {
                  return isConditionOr
                    ? "opacity-100 cursor-pointer"
                    : "bg-[#F0F0F0] text-[#808080] cursor-not-allowed pointer-events-none";
                }
                return "opacity-100 cursor-pointer";
              })();

              return (
                <div
                  key={index}
                  className={`rounded-[16px] border-[1px] p-[16px] flex flex-col gap-[8px] ${
                    isSelected ? "border-black" : "border-[#E6E6E6]"
                  } ${opacityClass}`}
                  onClick={() => handleVoucherClick(voucher)}
                >
                  <h3 className="text-[16px] leading-[22.4px] uppercase m-0 font-normal">
                    {voucher.attributes.voucher.data.attributes.code}
                  </h3>
                  <div className="flex justify-between items-end">
                    <p className="text-[12px] leading-[16.8px] text-[#545454]">
                      {voucher.attributes.voucher.data.attributes.title}
                    </p>
                    <span
                      className="text-[13px] leading-[18.2px] text-[#FF9900]"
                      onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                        event.stopPropagation();
                        showModal(voucher.attributes.voucher.data);
                      }}
                    >
                      Điều kiện
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };
  const uniqueVouchers = myVoucherList.reduce(
    (acc: BaseData<UserVouchers>[], current) => {
      const voucherId = current.attributes.voucher.data.id;

      if (
        !acc.some((voucher) => voucher.attributes.voucher.data.id === voucherId)
      ) {
        acc.push(current);
      }

      return acc;
    },
    []
  );
  const ceraneeVouchers = uniqueVouchers.filter(
    (item) =>
      item?.attributes?.voucher?.data?.attributes?.memberClass?.data === null
  );

  const voucherByRank = uniqueVouchers.filter(
    (item) =>
      item?.attributes?.voucher?.data?.attributes?.memberClass?.data !== null
  );

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] =
    useState<BaseData<Voucher> | null>(null);
  const showModal = (data: BaseData<Voucher>) => {
    setIsModalOpen(true);
    setSelectedCondition(data);
  };
  const handleUnuseVoucher = () => {
    setSelectedVoucher([]);
    setSelectedVouchers([]);
    closePopUp();
    cancleVouchers();
    setIsVoucherSelected(false);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModalLogin = () => {
    setOpenModal(true);
  };
  const closeModalLogin = () => {
    setOpenModal(false);
  };
  const [totalAmountDecrease, setTotalAmountDecrease] = useState<number>(0);
  useEffect(() => {
    const tempTotal = selectedVoucher.reduce((total, item) => {
      return total + Number(item.amountDecrease || 0);
    }, 0);
    setTotalAmountDecrease(tempTotal);
  }, [selectedVoucher?.length, totalAmountDecrease]);

  if (!profile?.id) {
    return (
      <>
        <div className="h-[276px] w-[500px] flex flex-col justify-center items-center gap-[8px]">
          <p className="text-[16px] font-[400] text-nowrap">
            Đăng nhập để sử dụng voucher
          </p>
          <button
            className="hover:text-[#FF9900] p-[10px_20px] rounded-[24px] bg-black text-white duration-300 text-[16px] font-[400] text-nowrap cursor-pointer"
            onClick={showModalLogin}
          >
            Đăng nhập
          </button>
        </div>
        <LoginModal
          isModalOpen={openModal}
          handleCancel={closeModalLogin}
          handleOk={closeModalLogin}
          onClose={onClose}
          closePopup={closePopUp}
        />
      </>
    );
  }
  if (ceraneeVouchers?.length === 0 && voucherByRank?.length === 0) {
    return (
      <div className="h-[276px] w-[500px] flex justify-center items-center">
        <p className="text-[16px] font-[400] text-nowrap">
          Không có voucher trong ví voucher
        </p>
      </div>
    );
  }
  return (
    <>
      <div
        className={` bg-[#fff] bottom-[20px]  ${
          isNewPage
            ? "w-full p-[24px_16px]"
            : "rounded-[24px] w-[500px] shadow-[0_0_16px_0_#0000000A] p-[24px]"
        } flex flex-col gap-[24px]`}
      >
        <>
          <Scrollbars
            style={{ maxWidth: "100%", height: "276px" }}
            autoHide
            renderTrackVertical={(props) => (
              <div {...props} className="track-vertical z-[9999]" />
            )}
          >
            <div className="mr-[15px]">
              <RenderVouchers
                vouchers={ceraneeVouchers}
                title="Voucher của Ceranee"
              />
              <RenderVouchers
                vouchers={voucherByRank}
                title="Voucher theo hạng"
              />
            </div>
          </Scrollbars>
        </>
        <div className="flex flex-col gap-[16px]">
          {(selectedVouchers.length > 0 || selectedVoucher.length > 0) &&
          !isVoucherSelected ? (
            <span className="font-medium text-[14px] leading-[19.6px] animate-fade-in-fast text-[#F04438]">
              Đơn hàng chưa đủ điều kiện sử dụng voucher này
            </span>
          ) : (
            (selectedVouchers.length > 0 || selectedVoucher.length > 0) &&
            isVoucherSelected && (
              <span className="font-medium text-[14px] leading-[19.6px] animate-fade-in-fast">
                Đã chọn {selectedVouchers?.length || selectedVoucher?.length}{" "}
                voucher, tiết kiệm {formatMoney(totalAmountDecrease)}
              </span>
            )
          )}

          <button
            className={`p-[12px_20px] w-full duration-300 text-[14px] font-[600] leading-[16.94px] rounded-[24px] ${
              selectedVouchers.length > 0 && isVoucherSelected
                ? "text-white hover:text-[#FF9900] bg-black"
                : "bg-[#F0F0F0] text-[#808080] cursor-not-allowed pointer-events-none"
            }`}
            disabled={!isVoucherSelected}
            onClick={handleSubmitVoucher}
          >
            Áp dụng
          </button>
          <button
            className="font-medium p-[12px_20px] w-full text-black border-[1px] hover:border-black rounded-[24px] border-transparent duration-300 text-[14px] leading-[16.94px]"
            onClick={handleUnuseVoucher}
          >
            Không sử dụng voucher
          </button>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        className="w-[500px]"
        centered
        onCancel={() => setIsModalOpen(false)}
        onOk={() => setIsModalOpen(false)}
        closable={false}
        styles={{
          content: { borderRadius: "24px" },
        }}
        rootClassName="voucher-modal"
      >
        <div className="p-[3px] rounded-[24px]">
          <h3 className="font-bold text-[14px] leading-[16.944px] text-center m-0 mb-[32px]">
            Điều kiện áp dụng voucher {selectedCondition?.attributes?.code}
          </h3>
          <div>
            {selectedCondition?.attributes?.expiryDate && (
              <div className="flex flex-col gap-[8px] mb-[24px]">
                <h3 className="text-[14px] font-bold leading-[16.94px] m-0">
                  Hạn sử dụng mã
                </h3>
                <p className="text-[14px] leading-[16.94px]">
                  {formatDate(selectedCondition?.attributes?.expiryDate)}
                </p>
              </div>
            )}

            <div className="flex flex-col gap-[8px]">
              <h3 className="text-[14px] font-bold leading-[16.94px] m-0">
                Điều kiện
              </h3>
              <ul className="list-none flex flex-col gap-[12px]">
                <li className="text-[14px] leading-[16.94px]">
                  + Giảm tối đa{" "}
                  {formatMoney(
                    Number(selectedCondition?.attributes?.amountDecrease)
                  )}
                </li>
                <li className="text-[14px] leading-[16.94px]">
                  + Mã giảm áp dụng cho hóa đơn mua hàng từ{" "}
                  {formatMoney(
                    Number(selectedCondition?.attributes?.minimumOrderAmount)
                  )}
                </li>
                <li className="text-[14px] leading-[16.94px]">
                  +{" "}
                  {selectedCondition?.attributes?.title ||
                    selectedCondition?.attributes?.description}
                </li>
              </ul>
            </div>
          </div>
          {/* <button
            className="rounded-[24px] text-white bg-black w-full py-[12px] mt-[32px]"
            // onClick={() => setIsModalOpen(false)}
          >
            Đã hiểu
          </button> */}
        </div>
      </Modal>
    </>
  );
};

export default VoucherPopoverContent;
