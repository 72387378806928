import ProductInformationApi from "@/api/productInformation";
import { BaseData } from "@/type/base/baseData";
import { CategoryType } from "@/type/common/product/category";
import { ProductInformationType } from "@/type/response/productInformation";
import { ingredientOrder } from "@/utils/ingredientOrder";
import keysReactQuery from "@/utils/keys";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "antd";
import { useState } from "react";

interface TableInformationProps {
  productId: string;
  category: BaseData<CategoryType>;
}

interface IModalTableInformationProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  productInfo: BaseData<ProductInformationType>[];
}

const MINUTES_STALE_TIME = 1;

export default function TableInformation({
  productId,
  category,
}: TableInformationProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data, isLoading } = useQuery({
    queryKey: [keysReactQuery.PRODUCT_INFORMATION, productId],
    queryFn: () => ProductInformationApi.getId(productId),
    staleTime: 1000 * 60 * MINUTES_STALE_TIME,
  });

  const productInfo =
    data?.data?.data?.sort((a, b) => {
      if (!ingredientOrder[category?.id]) {
        return (
          ingredientOrder["-1"].indexOf(a.attributes.description.data.id) -
          ingredientOrder["-1"].indexOf(b.attributes.description.data.id)
        );
      } else {
        return (
          ingredientOrder[category.id].indexOf(
            a.attributes.description.data.id
          ) -
          ingredientOrder[category.id].indexOf(b.attributes.description.data.id)
        );
      }
    }) || [];

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <div className="md:bg-[#F0F0F0] md:rounded-[24px] p-[16px] md:px-[24px]">
        <table className="min-w-full">
          <tbody>
            {productInfo
              .slice(0, 3)
              .map((item: BaseData<ProductInformationType>, index: number) => {
                return (
                  <tr key={index} className="">
                    <td className="py-[8xp] px-0 w-1/2 md:w-2/5 text-[14px] md:text-[16px] font-[600] leading-[19.6px] md:leading-[22.4px] border-r border-[#E6E6E6]">
                      {item.attributes.description.data.attributes.name}
                    </td>
                    <td className="py-[8px] pl-[24px] md:pl-[8px] w-1/2 md:w-3/5 text-[14px] md:text-[16px] font-[400] leading-[19.6px] md:leading-[22.4px]">
                      {item.attributes.value.split("\n").map((item, key) => (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      ))}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={2} className="text-center pt-[16px] md:pt-[24px]">
                <p
                  className="text-[13px] md:text-[14px] font-[400] text-[#FF9900] cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                >
                  Xem tất cả
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ModalTableInformation
        isModalOpen={isModalOpen}
        handleOk={() => setIsModalOpen(false)}
        handleCancel={() => setIsModalOpen(false)}
        productInfo={productInfo}
      />
    </>
  );
}

const ModalTableInformation = (props: IModalTableInformationProps) => {
  const { isModalOpen, handleOk, handleCancel, productInfo } = props;

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}
      width={743}
    >
      <div className="rounded-[24px] px-[24px] py-[16px] h-[450px] md:h-auto overflow-y-auto">
        <table className="min-w-full md:text-[16px] ">
          <tbody>
            {productInfo.map(
              (item: BaseData<ProductInformationType>, index: number) => {
                return (
                  <tr key={index} className="flex items-start">
                    <td className="py-[8xp] px-0 w-1/2 md:w-2/5 text-[14px] md:text-[16px] font-[600] leading-[19.6px] md:leading-[22.4px] border-r border-[#E6E6E6]">
                      {item.attributes.description.data.attributes.name}
                    </td>
                    <td className="py-[8px] pl-[24px] md:pl-[8px] w-1/2 md:w-3/5 text-[14px] md:text-[16px] font-[400] leading-[19.6px] md:leading-[22.4px]">
                      {item.attributes.value.split("\n").map((item, key) => (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      ))}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
