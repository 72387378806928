import faqChatsApi from "@/api/faqChatsApi";
import { QAType } from "@/type/response/faqChat";
import { useQuery } from '@tanstack/react-query';

export default function useQueryFAQChats() {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['faqChats'],
    queryFn: () => faqChatsApi.getAll(),
  });

  const qaList: QAType[] = []

  data?.data?.data?.forEach(qa => {
    qaList.push({
      id: qa?.id,
      question: qa?.attributes?.question,
      answer: qa?.attributes?.answer
    })
  })

  return { data, qaList, isLoading, isFetched };
}
