import { useState, useEffect, useRef } from "react";

function UseMaxHeight(initialState: number, isOpen: boolean) {
  const [maxHeight, setMaxHeight] = useState<number>(initialState);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;
    function updateMaxHeight() {
      if (ref.current) {
        const { scrollHeight } = ref.current;
        setMaxHeight(scrollHeight);
      }
    }

    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);
    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [isOpen]);

  return [ref, maxHeight] as const;
}

export default UseMaxHeight;
