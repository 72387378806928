import { GetFeeResponesType } from "@/type/response/order";
import { atom } from "recoil";

export const shippingDataState = atom<GetFeeResponesType>({
  key: "shippingDataState",
  default: {
    data: {
      fromDistrictId: 0,
      fromWardCode: "",
      serviceId: 0,
      serviceTypeId: 0,
      toDistrictId: 0,
      toWardCode: "",
      weight: 0,
    },
    fee: 0,
    type: "Giao hàng nhanh",
  },
});
