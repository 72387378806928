import svgs from "@/assets/svgs";
import { BaseData } from "@/type/base/baseData";
import { MembershipClass, UserVouchers } from "@/type/response/profile";
import { UserProfileType } from "@/type/response/user";
import constants from "@/utils/constants";
import { pathNameOrderStatusList, profileRank } from "@/utils/profile";
import routes from "@/utils/routes";
import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { OrderResponse } from "@/type/response/order";
import { Pagination } from "@/type/common/pagination";
import authApi from "@/api/authApi";
import uploadApi from "@/api/uploadApi";
import toast from "react-hot-toast";
import {
  // useQueryAllOrderStatus,
  useQueryMembershipClasses,
  useQueryOrders,
  useQueryUserVouchers,
} from "@/hooks/ReactQuery/profile.query";
import useWindowSize from "@/hooks/useWindowSize";
import Breadcrumb from "@/components/common/Breadcrumb";
import BackToHeader from "@/components/common/BackToHeader";
import getRankMemberShip from "@/utils/getRankMemberShip";
import BasicModal from "@/components/common/Modal";

type ContextType = {
  membershipClassesData: BaseData<MembershipClass>[];
  // isQueryMembershipClassesLoading: boolean;
  currentRank: MembershipClass;
  nextRank: MembershipClass;
  profile: UserProfileType;
  availableRankIdList: number[];
  allVouchers: BaseData<UserVouchers>[];
  isQueryUserVouchersLoading: boolean;
  orderList: BaseData<OrderResponse>[];
  isQueryOrdersLoading: boolean;
  informationPagination?: Pagination;
  handleChangeQueryOrdersParams: (newParams: string) => void;
  refetch: any;
};

type RankButtonPropsType = {
  currentRankName: string;
};

export const RankButton = ({ currentRankName }: RankButtonPropsType) => {
  const location = useLocation();
  switch (currentRankName) {
    case profileRank.DIAMOND:
      return (
        <Link to={`${routes.PROFILE_MEMBERSHIP}`}>
          <div
            className={`m-0 text-[14px] leading-[16.94px] underline text-nowrap underline-offset-2 ${
              location.pathname ===
              `${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`
                ? "text-[#FF9900] font-bold"
                : "font-medium text-black"
            }`}
          >
            {getRankMemberShip(profileRank.DIAMOND)}
          </div>
        </Link>
      );
    case profileRank.GOLD:
      return (
        <Link to={`${routes.PROFILE_MEMBERSHIP}`}>
          <div
            className={`m-0 text-[14px] leading-[16.94px] underline text-nowrap underline-offset-2 ${
              location.pathname ===
              `${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`
                ? "text-[#FF9900] font-bold"
                : "font-medium text-black"
            }`}
          >
            {getRankMemberShip(profileRank.GOLD)}
          </div>
        </Link>
      );
    case profileRank.SILVER:
      return (
        <Link to={`${routes.PROFILE_MEMBERSHIP}`}>
          <div
            className={`m-0 text-[14px] leading-[16.94px] underline text-nowrap underline-offset-2 ${
              location.pathname ===
              `${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`
                ? "text-[#FF9900] font-bold"
                : "font-medium text-black"
            }`}
          >
            {getRankMemberShip(profileRank.SILVER)}
          </div>
        </Link>
      );
    case profileRank.BRONZE:
    default:
      return (
        <Link to={`${routes.PROFILE_MEMBERSHIP}`}>
          <div
            className={`m-0 text-[14px] leading-[16.94px] underline text-nowrap underline-offset-2 ${
              location.pathname ===
              `${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`
                ? "text-[#FF9900] font-bold"
                : "font-medium text-black"
            }`}
          >
            {getRankMemberShip(profileRank.BRONZE)}
          </div>
        </Link>
      );
  }
};

export default function ProfileLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile = dataProfile ? JSON.parse(dataProfile) : {};

  const pathName = location.pathname.split("/").slice(-1)[0];

  const { membershipClassesData } = useQueryMembershipClasses();
  const { allVouchers, isQueryUserVouchersLoading } = useQueryUserVouchers();
  // const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const [statusList] = useState<string[]>(pathNameOrderStatusList[pathName]);

  const [openConfirmLogout, setOpenConfirmLogout] = useState<boolean>(false);
  const [searchParams] = useSearchParams({
    page: "1",
    pageSize: "5",
  });
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "5";

  let statusQuery: string[] = [];
  statusList?.forEach((status) =>
    statusQuery.push(`filters[status]=${status}`)
  );

  const [queryOrdersParams, setQueryOrdersParams] = useState(
    `pagination[page]=${page}&pagination[pageSize]=${pageSize}&${statusQuery.join(
      "&"
    )}`
  );

  useEffect(() => {
    setQueryOrdersParams(
      `pagination[page]=${page}&pagination[pageSize]=${pageSize}&${statusQuery.join(
        "&"
      )}`
    );
  }, [statusList]);

  // handle User Rank UI

  const sortedMembershipClassesData = membershipClassesData.sort(
    (a, b) => a.attributes.point - b.attributes.point
  );

  const currentPoint = profile?.point || 0;

  const availableRankList = sortedMembershipClassesData.filter(
    (item) => item.attributes.point >= currentPoint
  );

  const availableRankIdList = availableRankList.map((item) => item.id);

  const passedRankList = sortedMembershipClassesData.filter(
    (item) => item.attributes.point <= currentPoint
  );

  const currentRank = passedRankList[passedRankList.length - 1]?.attributes;

  const nextRankList = sortedMembershipClassesData.filter(
    (item) => item.attributes.point > currentPoint
  );

  const nextRank = !!nextRankList.length
    ? nextRankList[0].attributes
    : currentRank;

  // handle useQueryOrder

  const { orderList, isQueryOrdersLoading, informationPagination, refetch } =
    useQueryOrders(queryOrdersParams);

  const handleChangeQueryOrdersParams = (newParams: string) => {
    setQueryOrdersParams(newParams);
  };

  const logout = () => {
    localStorage.clear();
    window.dispatchEvent(new Event("storage"));
    navigate(0);
    window.location.replace("/login");
  };

  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [isChangeAvatar, setIsChangeAvatar] = useState<boolean>(false);
  useEffect(() => {
    const fetchUserProfile = async () => {
      await authApi.getUser().then((res) => {
        localStorage.setItem(constants.PROFILE, JSON.stringify(res.data));

        if (!res?.data?.data?.length) return;

        const newAvatarUrl =
          res?.data?.data?.length > 0
            ? res?.data?.data[0]?.attributes?.image?.data?.attributes?.url
            : "/";
        if (newAvatarUrl) {
          setAvatarUrl(newAvatarUrl);
        }
      });
    };

    fetchUserProfile();
  }, [isChangeAvatar]);

  const getBreadcrumb = (path: string) => {
    const mapPage = {
      [`${routes.PROFILE}/${routes.PROFILE_INFO}`]: {
        title: "Thông tin cá nhân",
        paths: `${routes.PROFILE}/${routes.PROFILE_INFO}`,
      },
      [`${routes.PROFILE}/${routes.ADDRESS_PROFILE}`]: {
        title: "Địa chỉ",
        paths: `${routes.PROFILE}/${routes.ADDRESS_PROFILE}`,
      },
      [`${routes.PROFILE}/${routes.ORDERS}`]: {
        title: "Theo dõi đơn hàng",
      },
      [`${routes.PROFILE}/${routes.ORDERS}/waiting`]: {
        title: "Theo dõi đơn hàng",
      },
      [`${routes.PROFILE}/${routes.ORDERS}/delivering`]: {
        title: "Theo dõi đơn hàng",
      },
      [`${routes.PROFILE}/${routes.ORDERS}/received`]: {
        title: "Theo dõi đơn hàng",
      },
      [`${routes.PROFILE}/${routes.ORDERS}/return-cancel`]: {
        title: "Theo dõi đơn hàng",
      },
      [`${routes.PROFILE}/${routes.ORDERS}/history`]: {
        title: "Theo dõi đơn hàng",
      },

      [`${routes.PROFILE}/${routes.VOUCHERS}`]: {
        title: "Ví voucher",
        paths: `${routes.PROFILE}/${routes.VOUCHERS}`,
      },
      [`${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`]: {
        title: "Xếp hạng thành viên",
        paths: `${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`,
      },
    };
    return mapPage[path] || null;
  };
  const size = useWindowSize();

  const breadCrumbData = getBreadcrumb(location.pathname);
  const getActivePage = (pathname: string): number => {
    const mapPage = {
      [`${routes.PROFILE}/${routes.PROFILE_INFO}`]: 0,
      [`${routes.PROFILE}/${routes.ADDRESS_PROFILE}`]: 1,
      [`${routes.PROFILE}/${routes.ORDERS}`]: 2,
      [`${routes.PROFILE}/${routes.ORDERS}/waiting`]: 2,
      [`${routes.PROFILE}/${routes.ORDERS}/delivering`]: 2,
      [`${routes.PROFILE}/${routes.ORDERS}/received`]: 2,
      [`${routes.PROFILE}/${routes.ORDERS}/return-cancel`]: 2,
      [`${routes.PROFILE}/${routes.ORDERS}/history`]: 2,
      [`${routes.PROFILE}/${routes.VOUCHERS}`]: 3,
      [`${routes.SUPPORT}`]: 4,
      [`${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`]: 5,
    };

    return mapPage[pathname] !== undefined ? mapPage[pathname] : -1;
  };
  //breadcrumb
  const breadcrumbItems =
    size.width < 768
      ? [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            href: routes.PROFILE,
            title: "Cá nhân",
          },
          {
            href: breadCrumbData?.paths,
            title: breadCrumbData?.title,
          },
        ]
      : [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            href: routes.PROFILE,
            title: "Cá nhân",
          },
          {
            href: breadCrumbData?.paths,
            title: breadCrumbData?.title,
          },
        ];

  //pages
  const [activePages, setActivePages] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const pages = [
    {
      title: "Thông tin cá nhân",
      url: `${routes.PROFILE_INFO}`,
      hasIcon: true,
      isLogout: false,
    },
    {
      title: "Địa chỉ",
      url: `${routes.ADDRESS_PROFILE}`,
      hasIcon: true,
      isLogout: false,
    },
    {
      title: "Theo dõi đơn hàng",
      url: `${routes.ORDERS}/waiting`,
      hasIcon: true,
      isLogout: false,
    },
    {
      title: "Ví voucher",
      url: `${routes.VOUCHERS}`,
      hasIcon: true,
      isLogout: false,
    },
    {
      title: "Trợ giúp",
      url: `${routes.SUPPORT}`,
      hasIcon: false,
      isLogout: false,
    },
    {
      title: "Đăng xuất",
      url: `${routes.PROFILE_INFO}`,
      hasIcon: false,
      isLogout: true,
    },
  ];

  useEffect(() => {
    if (location.pathname === routes.PROFILE) {
      navigate(`${routes.PROFILE}/${routes.PROFILE_INFO}`);
    }

    const currentPage = getActivePage(location.pathname);
    if (currentPage !== -1) {
      setActivePages(currentPage);
    }
  }, [location.pathname]);

  const handleOk = async () => {
    if (fileInputRef.current?.files && fileInputRef.current.files.length > 0) {
      const file = fileInputRef.current.files[0];

      try {
        const response = await uploadApi.uploadFile(file);
        if (response.status === 200 && response.data) {
          setAvatarUrl(response?.data[0]?.url);
          const newData = {
            avatar: { id: response?.data[0]?.id },
          };

          try {
            const res = await authApi.updateUser(newData);
            if (res) {
              toast.success("Đặt lại ảnh đại diện thành công");
              setIsChangeAvatar((prev) => !prev);
            }
          } catch (updateError) {
            console.error("Update user failed:", updateError);
            toast.error("Không thể cập nhật ảnh đại diện");
          }
        } else {
          toast.error("Tải lên không thành công");
        }
      } catch (uploadError) {
        console.error("Upload failed:", uploadError);
        toast.error("Không thể tải lên ảnh");
      } finally {
        setIsModalOpen(false);
        setImageUrl(null);
      }
    } else {
      toast.error("Vui lòng chọn một tệp tin");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setImageUrl(null);
  };

  // const handleClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const url = URL.createObjectURL(file);
  //     setImageUrl(url);
  //     showModal();
  //   }
  // };

  const handleBackToAddress = () => {
    navigate(routes.PROFILE_INFO);
  };

  return (
    <>
      {width > 768 || getActivePage(location.pathname) !== 1 ? (
        <Breadcrumb items={breadcrumbItems} />
      ) : (
        <BackToHeader content="Địa chỉ" onBackTo={handleBackToAddress} />
      )}

      <div className="bg-white max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] justify-center w-full mx-auto">
        <div className="flex md:flex-row flex-col md:gap-[8px] md:min-h-[630px] bg-[#f7f7f7]">
          <section className="bg-white md:py-[32px] pr-[32px] md:flex flex-col gap-[32px] hidden">
            <div className="flex flex-col gap-[24px] items-center">
              <div
                className={`size-[100px] bg-[#f7f7f7] rounded-full ${
                  profile?.avatar ? "" : "pt-[15px]"
                } overflow-hidden relative`}
              >
                <img
                  src={avatarUrl || profile?.avatar?.url || svgs.avatarDefault}
                  className={`w-full h-full rounded-full ${
                    profile?.avatar ? "object-cover" : ""
                  }`}
                />
                {/* <div className="relative">
                  <div
                    className="absolute bottom-[0] left-[0] cursor-pointer bg-gradient-to-b from-[#99999900] to-[#00000080] p-[8px_32px_0_32px] w-full flex items-center justify-center h-[40px]"
                    onClick={handleClick}
                  >
                    <span className="text-[14px] font-medium leading-[16px] text-white ">
                      Sửa
                    </span>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    // onChange={handleImageChange}
                  />
                </div> */}
              </div>
              <div className="flex flex-col gap-[8px] items-center">
                <h3 className="m-0 font-[600] text-[20px] leading-[24.2px] text-nowrap">
                  {profile?.fullName}
                </h3>
                <RankButton currentRankName={currentRank?.name} />
              </div>
              <div>
                <ul className="list-none flex flex-col items-center gap-[12px]">
                  {pages.map((item, index) => (
                    <li
                      className={`flex items-center justify-between px-[14px] cursor-pointer ${
                        !item?.hasIcon && "h-[32px]"
                      } md:w-[223px]`}
                      onClick={() => {
                        if (item?.isLogout) {
                          setOpenConfirmLogout(true);
                        } else {
                          if (item?.url === `${routes.SUPPORT}`) {
                            navigate(`/${item?.url}`);
                          } else {
                            navigate(`${routes.PROFILE}/${item?.url}`);
                          }
                          setActivePages(index);
                        }
                      }}
                      key={index}
                    >
                      <span
                        className={`${
                          item?.isLogout && "text-[#FF9900]"
                        } text-[16px] leading-[19.36px] hover:font-bold hover:text-[#FF9900] duration-300 ${
                          activePages === index && "text-[#FF9900] font-bold"
                        } py-[10.5px]`}
                      >
                        {item?.title}
                      </span>
                      {item?.hasIcon && (
                        <div className="p-[3px] hover:bg-[#d9d9d9] rounded-full duration-300">
                          <img
                            src={svgs.chevron}
                            className="rotate-[-90deg] size-[32px]"
                          />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="bg-white flex-1 w-full overflow-hidden">
            <Outlet
              context={
                {
                  membershipClassesData,
                  // isQueryMembershipClassesLoading,
                  currentRank,
                  nextRank,
                  profile,
                  availableRankIdList,
                  allVouchers,
                  isQueryUserVouchersLoading,
                  orderList,
                  isQueryOrdersLoading,
                  informationPagination,
                  handleChangeQueryOrdersParams,
                  refetch,
                } satisfies ContextType
              }
            />
          </section>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Hủy"
        okText="Cập nhật"
        centered
      >
        {imageUrl && (
          <div className="flex items-center justify-center overflow-y-auto max-h-auto">
            <img
              src={imageUrl}
              alt="Uploaded"
              className="w-full h-full object-contain"
            />
          </div>
        )}
      </Modal>

      <BasicModal
        rootClassName="confirm confirm-logout"
        isModalOpen={openConfirmLogout}
        okText="Đăng xuất"
        cancelText="Hủy"
        handleOk={logout}
        handleCancel={() => setOpenConfirmLogout(false)}
        closeIcon={false}
        title={false}
        width={380}
        centered
      >
        <div className="h-[32px]">
          <p className="text-[16px] text-[#484848] text-center ">
            Bạn muốn đăng xuất khỏi tài khoản?
          </p>
        </div>
      </BasicModal>
    </>
  );
}

export function useProfileLayoutContext() {
  return useOutletContext<ContextType>();
}
