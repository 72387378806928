import "@/assets/scss/product_filter.antd.scss";
import useFilterSkinPropertiesQuery from "@/hooks/ReactQuery/productFilter.query";
import {
  convertToMenuItems,
  getItemMenuAntd,
} from "@/hooks/convertToMenuItems";
import getSearchParams from "@/utils/getSearchParams";
import { ConfigProvider, Drawer, Menu, MenuProps } from "antd";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import FooterDrawer from "../FooterDrawer";
import svgs from "@/assets/svgs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  confirmFilter,
  loadingSpin,
  resetFilterTimeState,
} from "@/recoil/filterProduct";
import clsx from "clsx";

interface IFilterComponentProps {
  handleChangeFilter: (params: Record<string, string[] | string>) => void;
  layoutDesktop?: boolean | false;
  isFilterFlashSale: boolean;
  handleSetIsFilterFlashSale: (isFiter: boolean) => void;
}

const initialFilter: Record<string, string[]> = {
  product_lines: [],
  skin_properties: [],
  brand: [],
  product_texture: [],
};

export default function FilterComponent({
  handleChangeFilter,
  layoutDesktop,
  isFilterFlashSale,
  handleSetIsFilterFlashSale,
}: IFilterComponentProps) {
  const searchParamsValue = getSearchParams();
  const [statusDrawer, setStatusDrawer] = useState(false);
  const [selectFilter, setSelectFilter] =
    useState<Record<string, string[]>>(initialFilter);
  const { skinProperties, lines, brands, textures } =
    useFilterSkinPropertiesQuery();

  const [statusButton, setStatusButton] = useState(
    Object.values(selectFilter).reduce(
      (total, item) => total + item.length,
      0
    ) > 0
  );

  const setConfirmButton = useSetRecoilState(confirmFilter);
  const setIsLoadingSpin = useSetRecoilState(loadingSpin);
  const resetFilterTime = useRecoilValue(resetFilterTimeState);
  useEffect(() => {
    const checkStatusButton = () => {
      const totalFilter = Object.values(selectFilter).reduce(
        (total, item) => total + item.length,
        0
      );
      setStatusButton(totalFilter > 0 || isFilterFlashSale);
    };

    checkStatusButton();
  }, [selectFilter]);

  useEffect(() => {
    if (Object.keys(searchParamsValue).length > 0) {
      setSelectFilter((prev) => {
        let newSelectFilter = { ...prev };
        Object.entries(searchParamsValue).forEach(([key, value]) => {
          if (key !== "categories") {
            newSelectFilter[key] = value;
          } else {
            delete newSelectFilter[key];
          }
        });
        return newSelectFilter;
      });
    }
  }, []);

  // Reset when select quickFilter
  useEffect(() => {
    setConfirmButton(true);
    setStatusButton(false);
    setSelectFilter(initialFilter);
    handleSetIsFilterFlashSale(false);
  }, [resetFilterTime]);

  const handleConfirmFilter = () => {
    setConfirmButton(true);
    setStatusDrawer(false);
    toast.success("Bộ lọc đã được áp dụng");
  };

  const handleRemoveFilter = () => {
    setConfirmButton(true);
    setStatusButton(false);
    setSelectFilter(initialFilter);
    handleChangeFilter(initialFilter);
    handleSetIsFilterFlashSale(false);
    toast.success("Bộ lọc đã được xóa");
  };

  const handleSelectFilter: MenuProps["onClick"] = (e) => {
    setIsLoadingSpin(false);
    setConfirmButton(false);
    handleSetIsFilterFlashSale(false);
    if (selectFilter[e.keyPath[1]].includes(e.key) == false) {
      let selectFilterNew = {
        ...selectFilter,
        [e.keyPath[1]]: [...selectFilter[e.keyPath[1]], e.key],
      };

      setSelectFilter(selectFilterNew);
      handleChangeFilter(selectFilterNew);
    }
  };

  const handleDeselect: MenuProps["onClick"] = (e) => {
    setIsLoadingSpin(false);
    setConfirmButton(false);
    setStatusButton(true);
    handleSetIsFilterFlashSale(false);

    if (selectFilter[e.keyPath[1]].includes(e.key)) {
      let selectFilterNew = {
        ...selectFilter,
        [e.keyPath[1]]: selectFilter[e.keyPath[1]].filter(
          (item) => item !== e.key
        ),
      };

      setSelectFilter(selectFilterNew);
      handleChangeFilter(selectFilterNew);
    } else {
      console.log("Error");
    }
  };

  const item_productLines: MenuProps["items"] = [
    getItemMenuAntd(
      "Dòng sản phẩm",
      "product_lines",
      undefined,
      convertToMenuItems(lines.data, "product_lines")
    ),
  ];

  const items_skinProperties: MenuProps["items"] = [
    getItemMenuAntd(
      "Tình trạng da",
      "skin_properties",
      undefined,
      convertToMenuItems(skinProperties.data, "skin_properties")
    ),
  ];

  const items_brands: MenuProps["items"] = [
    getItemMenuAntd(
      "Thương hiệu",
      "brand",
      undefined,
      convertToMenuItems(brands.data, "brand")
    ),
  ];

  const items_product_textures: MenuProps["items"] = [
    getItemMenuAntd(
      "Kết cấu sản phẩm",
      "product_texture",
      undefined,
      convertToMenuItems(textures.data, "product_texture")
    ),
  ];
  const menuList = [
    items_skinProperties,
    item_productLines,
    items_brands,
    items_product_textures,
  ];

  const handleClickFlashSaleFilter = () => {
    setIsLoadingSpin(false);
    setConfirmButton(false);
    setStatusButton(true);
    handleSetIsFilterFlashSale(true);
    handleChangeFilter({ filterFlashSale: "true" });
    setSelectFilter(initialFilter);
  };

  return (
    <>
      <div>
        <div
          className="text-[14px] md:text-[16px] font-[400] text-black flex items-center gap-[16px] leading-[19.36px] cursor-pointer"
          onClick={() => setStatusDrawer(true)}
        >
          <img src={svgs.filter} alt="" />
          Bộ lọc
        </div>
        <Drawer
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span className="text-[20px] font-[500] leading-[24.2px] text-black">
                Bộ lọc
              </span>
              <div
                className="hover:bg-[#0000001A] w-[48px] h-[48px] flex justify-center items-center rounded-full cursor-pointer"
                onClick={() => setStatusDrawer(false)}
              >
                <img
                  src={svgs.arrowLeftBlack}
                  alt="close-filter"
                  className="w-[8px] h-[16px]"
                />
              </div>
            </div>
          }
          placement={"left"}
          rootStyle={{ overflow: "hidden" }}
          closable={false}
          open={statusDrawer}
          key={"left"}
          width={layoutDesktop ? "320px" : "70%"}
          onClose={() => setStatusDrawer(false)}
          height={"100%"}
          footer={
            statusButton && (
              <FooterDrawer
                statusButton={statusButton}
                handleConfirmFilter={handleConfirmFilter}
                handleRemoveFilter={handleRemoveFilter}
              />
            )
          }
          styles={{
            body: {
              padding: 0,
            },
            footer: {
              border: 0,
              padding: "24px 32px 32px 32px",
            },
            content: {
              borderRadius: "0 24px 24px 0",
              overflow: "hidden",
            },
            header: {
              borderBottom: "1px solid #E6E6E6",
              padding: "12px 32px",
            },
          }}
          rootClassName="z-[2000]"
        >
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  activeBarBorderWidth: 0,
                  itemColor: "#484848",
                  colorInfoText: "#484848",
                  itemBg: "#fff",
                  itemActiveBg: "#fff",
                  colorBgBase: "#fff",
                  itemHoverBg: "#fff",
                  itemSelectedBg: "#fff",
                  subMenuItemBg: "#fff",
                  colorText: "#484848",
                  colorBgTextActive: "#484848",
                  itemSelectedColor: "#484848",
                  paddingContentVertical: 0,
                  paddingContentHorizontal: 0,
                  itemPaddingInline: 0,
                },
              },
            }}
          >
            <div className="mx-[20px] md:mx-[32px] pt-[8px] pb-[16px] border-b-[1px] border-solid border-[#E6E6E6]">
              <p
                className={clsx(
                  "pl-[1px] md:pl-0 text-[16px] cursor-pointer font-bold ",
                  isFilterFlashSale ? "text-[#ff9900]" : "text-black"
                )}
                onClick={handleClickFlashSaleFilter}
              >
                Flash Sale
              </p>
            </div>
            {menuList.map((menu, index) => (
              <Menu
                key={index}
                mode="inline"
                items={menu}
                onSelect={(e) => handleSelectFilter(e)}
                onDeselect={(e) => handleDeselect(e)}
                selectedKeys={selectFilter[menu[0]?.key as number]}
                defaultSelectedKeys={selectFilter[menu[0]?.key as number]}
                defaultOpenKeys={Object.keys(searchParamsValue)}
                expandIcon={null}
                openKeys={[
                  "skin_properties",
                  "product_lines",
                  "brand",
                  "product_texture",
                ]}
              />
            ))}
          </ConfigProvider>
        </Drawer>
      </div>
    </>
  );
}
