import { atom } from "recoil";

export const confirmFilter = atom<boolean>({
  key: "confirmFilter",
  default: true,
});

export const filterCountProduct = atom<number>({
  key: "filterCountProduct",
  default: 0,
});

export const isLoadingFilter = atom<boolean>({
  key: "isLoadingFilter",
  default: false,
});

export const loadingSpin = atom<boolean>({
  key: "loadingSpin",
  default: false,
});

export const resetFilterTimeState = atom<string>({
  key: "resetFilterTimeState",
  default: "",
});
