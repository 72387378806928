import productFaqsApi from "@/api/productFaqs";
import { useQuery } from "@tanstack/react-query";

const MINUTES_STALE_TIME = 1;

export default function useQueryQAProduct(id: string) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["productFAQ", id],
    queryFn: () => productFaqsApi.getId(id),
    staleTime: 1000 * 60 * MINUTES_STALE_TIME,
    enabled: !!id,
  });

  const productFaqs = data?.data?.data || [];

  return { productFaqs, isLoading, isFetched };
}
