import authApi from "@/api/authApi";
import images from "@/assets/images";
import NewInputField from "@/components/common/Formfield/NewInputField";
import { formValidation } from "@/utils/formValidation";
import routes from "@/utils/routes";
import { Image, Modal } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface IResetPasswordSuccessModalProps {
  isModalOpen: boolean;
  username?: string;
}
interface IResetPasswordProps {
  password: string;
  confirmPassword: string;
}
const ResetPasswordSuccessModal = ({
  isModalOpen,
  username,
}: IResetPasswordSuccessModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      title=""
      closeIcon={false}
      open={isModalOpen}
      centered
      width={"360px"}
      footer={null}
    >
      <div className="relative overflow-hidden mx-[-24px] my-[-20px] p-[24px_16px] rounded-[24px]">
        <p className="text-[16px] text-center px-6 mb-4">
          Xin chào {username || "mừng"} !
          <br />
          Đặt lại mật khẩu thành công
        </p>
        <div className="flex justify-center">
          <div
            className="w-[173px] flex justify-center bg-[#000000] text-[#FFFFFF] rounded-[100px] py-2 cursor-pointer"
            onClick={() => navigate(routes.HOME)}
          >
            <span>TIẾP TỤC MUA SẮM</span>
          </div>
        </div>
        <div className="absolute bottom-[-10px] left-[-5px] ">
          <Image
            src={images.welcome1}
            width={100}
            height={100}
            preview={false}
          />
        </div>
        <div className="absolute bottom-[10px] right-[-10px] ">
          <Image src={images.welcome2} width={63} height={58} preview={false} />
        </div>
      </div>
    </Modal>
  );
};
function ResetPasswordComponent() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IResetPasswordProps>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });
  const [password] = watch(["password"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModal] = useState<boolean>(false);
  const [codeReset, setCodeReset] = useState<string>("");
  const navigate = useNavigate();
  const onSubmit = async (data: IResetPasswordProps) => {
    const newData = {
      password,
      passwordConfirmation: data?.confirmPassword,
      code: codeReset,
    };
    setIsLoading(true);
    await authApi
      .resetPassword(newData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Đặt lại mật khẩu thành công");
          navigate(routes.HOME);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          `Có lỗi khi đặt lại mật khẩu : ${error?.response?.data?.error?.message}`
        );
      });
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code") || "";
    setCodeReset(code);
  }, [isLoading]);
  return (
    <>
      <div className="bg-white lg:p-[32px_160px]">
        <h2 className="font-[600] text-[30px] text-center">Đặt lại mật khẩu</h2>
        <form
          className="flex flex-col gap-[32px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NewInputField
            control={control}
            isPassword
            name="password"
            label="Mật khẩu *"
            placeholder="Nhập mật khẩu"
            rules={formValidation.password}
            errors={errors.password}
          />
          <NewInputField
            control={control}
            isPassword
            name="confirmPassword"
            label="Nhập lại mật khẩu *"
            placeholder="Nhập lại mật khẩu"
            rules={formValidation.passwordConfirm(password)}
            errors={errors.confirmPassword}
          />
          <button
            className={`p-[12px_20px] text-[14px] font-[600] rounded-[24px] w-full lg:min-w-[400px] duration-300 mt-[32px] ${
              isLoading
                ? "bg-[#E6E6E6] text-[#818080] cursor-not-allowed"
                : "bg-black text-white hover:text-[#FF9900]"
            }`}
            disabled={isLoading}
          >
            Hoàn thành
          </button>
        </form>
      </div>
      <ResetPasswordSuccessModal
        isModalOpen={isOpenModal}
        // username={userData?.username}
      />
    </>
  );
}

export default ResetPasswordComponent;
