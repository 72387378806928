import loadingScreenState from "@/recoil/loadingScreenState";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

const LOADING_DURATION = 2;
const PARTIAL_LOAD_DURATION = LOADING_DURATION * 0.85;
const FULL_LOAD_DURATION = LOADING_DURATION - PARTIAL_LOAD_DURATION;
const WAIT_TIME = 1.5; // seconds

interface ILoadingProps {
  isLoadingDone: boolean;
}

const Loading = ({ isLoadingDone }: ILoadingProps) => {
  const [progress, setProgress] = useState(0);
  const [isPreLoadDone, setIsPreLoadDone] = useState(false);
  const eyeControls = useAnimation();
  const textControls = useAnimation();
  const lineControls = useAnimation();

  const handleSetIsLoading = useSetRecoilState(loadingScreenState);

  useEffect(() => {
    const startAnimations = async () => {
      // Start eye movement animation
      await Promise.all([
        eyeControls.start({
          top: ["65px", "67px", "68px", "67px"],
          left: ["15px", "26.25px", "37.5px", "48.75px"],
          transition: {
            duration: PARTIAL_LOAD_DURATION,
            ease: "linear",
          },
        }),
        lineControls.start({
          width: "85%",
          transition: {
            duration: PARTIAL_LOAD_DURATION,
            ease: "linear",
          },
        }),
        textControls.start({
          x: "85%",
          transition: {
            duration: PARTIAL_LOAD_DURATION,
            ease: "linear",
          },
        }),
      ]).then(() => {
        setIsPreLoadDone(true);
        // Update progress state to 85%
        setProgress(85);
      });
    };

    startAnimations();
  }, []);

  useEffect(() => {
    if (isPreLoadDone) {
      if (isLoadingDone) {
        completeLoading();
      } else {
        setTimeout(() => {
          completeLoading();
        }, WAIT_TIME * 1000);
      }
    }
  }, [isLoadingDone, isPreLoadDone]);

  const completeLoading = async () => {
    // Continue loading from 85% to 100%
    await Promise.all([
      eyeControls.start({
        top: ["67px", "65px"],
        left: ["48.75px", "60px"], // Static final eye position
        transition: {
          duration: FULL_LOAD_DURATION,
          ease: "linear",
        },
      }),
      lineControls.start({
        width: "100%",
        transition: {
          duration: FULL_LOAD_DURATION,
          ease: "linear",
        },
      }),
      textControls.start({
        x: "100%",
        transition: {
          duration: FULL_LOAD_DURATION,
          ease: "linear",
        },
      }),
    ]);

    setProgress(100);
    handleSetIsLoading(false); // End loading
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-[56px] overflow-hidden z-[2000]">
      <div className="flex space-x-4">
        {/* Left Eye */}
        <div className="relative w-[160px] h-[160px] rounded-full bg-white bg-eyes-gradient">
          <motion.div
            className="absolute w-[80px] h-[80px] bg-black rounded-full shadow-[0px_4px_4px_0_rgba(0, 0, 0, 0.25)]"
            animate={eyeControls}
          />
        </div>
        {/* Right Eye */}
        <div className="relative w-[160px] h-[160px] rounded-full bg-white bg-eyes-gradient">
          <motion.div
            className="absolute w-[80px] h-[80px] bg-black rounded-full shadow-[0px_4px_4px_0_rgba(0, 0, 0, 0.25)]"
            animate={eyeControls}
          />
        </div>
      </div>

      {/* Loading Line */}
      <div className="relative w-[306px] h-[4px] bg-[#D9D9D9] rounded-[16px]">
        <motion.p
          className="absolute top-[-42px] left-[-12px] text-[30px] font-[700] text-[#000] w-full"
          style={{ x: `${progress}%` }}
          animate={textControls}
        >
          C
        </motion.p>
        <motion.div
          className="absolute left-0 bottom-0 h-full bg-[#000] rounded-[16px]"
          style={{ width: `${progress}%` }}
          animate={lineControls}
        />
      </div>
    </div>
  );
};

export default Loading;
