/* eslint-disable import/extensions */
import { BaseData } from "@/type/base/baseData";
import { BaseResponse } from "@/type/base/baseResponse";
import { ProductDetailsType } from "@/type/response/productDetails";
import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { ProductPageType, ProductsResponse } from "@/type/response/products";
import { ReviewType } from "@/type/common/product/review";
import { product } from "@/utils/product";
import { CommentType } from "@/type/common/product/comment";
import { Variation } from "@/type/response/productInformation";

const productApi = {
  getAll(paramsFilter?: string) {
    return axiosClient.get<BaseResponse<BaseData<ProductsResponse>[]>>(
      `${urls.PRODUCTS}?populate[0]=avatar&populate[1]=variation_prod_attribute&populate[2]=skin_properties&populate[3]=product_unit&${paramsFilter}`
    );
  },

  getById(id: string) {
    return axiosClient.get<BaseResponse<BaseData<ProductDetailsType>>>(
      `${urls.PRODUCTS}/${id}?populate[0]=avatar&populate[1]=gallery&populate[2]=variation_products.avatar&populate[3]=categories&populate[4]=product_unit&populate[5]=SEO&populate[6]=product_lines&populate[7]=variation_prod_attribute`
    );
  },

  getBySlug(slug: string) {
    return axiosClient.get<BaseResponse<BaseData<ProductDetailsType>>>(
      `${urls.PRODUCT}/${slug}?populate[0]=avatar&populate[1]=gallery&populate[2]=variation_products.avatar&populate[3]=categories&populate[4]=product_unit&populate[5]=SEO&populate[6]=product_lines&populate[7]=variation_prod_attribute`
    );
  },

  getByFilter(params: Record<string, string>) {
    return axiosClient.get<BaseResponse<BaseData<ProductsResponse>[]>>(
      `${urls.PRODUCTS}?${new URLSearchParams(params).toString()}`
    );
  },

  getVariations(slug: string) {
    return axiosClient.get<Variation[]>(`${urls.VARIATIONS}/${slug}`);
  },

  getProductReviews(productId: number, page: number) {
    return axiosClient.get<BaseResponse<BaseData<ReviewType>[]>>(
      `reviews?filters[product]=${productId}&pagination[page]=${page}&pagination[pageSize]=${product.MAX_PAGE_SIZE}&populate=deep,3&sort[0]=createdAt:desc`
    );
  },

  getAllReviews(productId: number) {
    return axiosClient.get<BaseResponse<BaseData<ReviewType>[]>>(
      `reviews?filters[product]=${productId}&populate=deep,3&sort[0]=createdAt:desc`
    );
  },

  getProductComments(productId: number, page: number) {
    return axiosClient.get<BaseResponse<BaseData<CommentType>[]>>(
      `comments?filters[product]=${productId}&pagination[page]=${page}&pagination[pageSize]=${product.MAX_PAGE_SIZE}&populate=deep&sort[0]=createdAt:desc`
    );
  },

  getAllComments(productId: number) {
    return axiosClient.get<BaseResponse<BaseData<CommentType>[]>>(
      `comments?filters[product]=${productId}&populate=deep&sort[0]=createdAt:desc`
    );
  },

  checkValidUserToComment(body: { product_id: string }) {
    return axiosClient.post<boolean>(`check-valid-user-to-comment`, body);
  },

  commentProduct(body: Record<string, string>) {
    return axiosClient.post<boolean>(`comments`, body);
  },
  productPage() {
    return axiosClient.get<BaseResponse<BaseData<ProductPageType>>>(
      urls.PRODUCT_PAGE
    );
  },
};

export default productApi;
