import Blog from "@/components/common/Blog";
// import Product from "@/components/common/Product";
import useBlogsQuery, {
  useFrequentlyQuestions,
} from "@/hooks/ReactQuery/blogs.query";
import useProductsQuery from "@/hooks/ReactQuery/products.query";
import routes from "@/utils/routes";
import Breadcrumb from "@/components/common/Breadcrumb";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Product from "@/components/common/NewProduct";
import QuestionPartner from "@/components/common/QuestionPartner";

function SearchResult() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const { products } = useProductsQuery();
  const filteredProducts = products?.filter((product) =>
    product.attributes.name.toLowerCase().includes(searchKey.toLowerCase())
  );

  const { blogList } = useBlogsQuery("3", "1");
  const filteredBlogs = blogList?.filter((blog) =>
    blog.attributes.shortDescription
      .toLowerCase()
      .includes(searchKey.toLowerCase())
  );

  const { questionList } = useFrequentlyQuestions();
  const filteredQuestionList = questionList?.filter((qs) =>
    qs?.attributes?.title.toLowerCase().includes(searchKey.toLowerCase())
  );

  const handleChangeTab = (index: number) => {
    setSelectedTab(index);
  };

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.PRODUCT,
      title: "Sản phẩm",
    },
  ];

  const tabs = ["Sản phẩm", "Bài viết", "Q&A"];
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get("searchKey") || "";
    setSearchKey(key);
  }, [location.search]);

  return (
    <>
      <Breadcrumb items={breadcrumbItems} className="md:block hidden" />
      <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] justify-center py-[8px] w-full mx-auto">
        <p className="font-[300] text-[12px] leading-[14.52px] mt-[16px] md:mt-[32px] mb-[20px] md:mb-[40px]">
          Kết quả tìm kiếm cho “{searchKey}”
        </p>

        <div className="mb-[16px]">
          <div className="mb-[32px] flex items-center justify-center md:justify-start">
            {tabs.map((item, index) => (
              <>
                <span
                  className={`flex-1 md:flex-[unset] text-center font-[400] text-[16px] leading-[22.4px] px-[16px] py-[8px] cursor-pointer ${
                    selectedTab === index &&
                    "border-b-[1px] text-[#000] font-bold border-[#000]"
                  }`}
                  key={index}
                  onClick={() => handleChangeTab(index)}
                >
                  {item}
                </span>
                {index !== 2 && (
                  <span className="border-l h-[16px] border-[#C6C6C6]"></span>
                )}
              </>
            ))}
          </div>
          {selectedTab === 0 && (
            <div className="mt-[10px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-[16px]">
              {filteredProducts && filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  // <div className="w-[167px]">
                  // <Product
                  //   key={product.id}
                  //   product={product}
                  //   showVariation
                  //   hiddenIconAddCart
                  //   showButtonBuy
                  //   showDiscountHead
                  //   showRatting
                  //   inProducts
                  // />
                  <Product
                    key={product.id}
                    product={product}
                    thumbType={"primary"}
                    contentPage={"products"}
                  />
                  // </div>
                ))
              ) : (
                <div className="min-h-[300px] flex justify-center items-center">
                  <p className="font-medium text-[16px]">
                    Không có sản phẩm cần tìm
                  </p>
                </div>
              )}
            </div>
          )}
          {selectedTab === 1 && (
            <div className="mt-[10px] flex flex-col gap-[16px] items-center">
              {filteredBlogs.length > 0 ? (
                filteredBlogs?.map((item) => (
                  <Blog blog={item} inSearchResult />
                ))
              ) : (
                <div className="min-h-[300px] flex justify-center items-center">
                  <p className="font-medium text-[16px]">
                    Không có bài viết cần tìm
                  </p>
                </div>
              )}
            </div>
          )}
          {selectedTab === 2 && (
            <div className="mt-[10px] flex flex-col gap-[16px] items-center">
              {filteredQuestionList?.length > 0 ? (
                filteredQuestionList.map((faq, index) => {
                  const question = {
                    id: faq.id,
                    title: faq.attributes.title || "",
                    content:
                      faq.attributes?.content?.[0].children?.[0].text || "",
                  };
                  return (
                    <QuestionPartner
                      key={index}
                      question={question}
                      inSearchResult
                    />
                  );
                })
              ) : (
                <div className="min-h-[300px] flex justify-center items-center">
                  <p className="font-medium text-[16px]">
                    Không có câu hỏi cần tìm
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchResult;
