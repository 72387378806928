import userAddressApi from "@/api/userAddressApi";
import { useQuery } from "@tanstack/react-query";

export default function useUserAddress(searchParams?: string) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["useUserAddress", searchParams],
    queryFn: () => userAddressApi.getAll(searchParams),
  });

  return {
    address: data?.data.data,
    isLoading,
    isFetched,
    addressPagination: data?.data?.meta?.pagination,
  };
}
