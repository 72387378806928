import { useState } from "react";
import {
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import jsonData from "../../../navBar.json";
import routes from "@/utils/routes";
import useHomeQueryData from "@/hooks/ReactQuery/home.query";
import { CategoriesTree } from "@/type/response/home";
import urls from "@/utils/urls";
import constants from "@/utils/constants";
import images from "@/assets/images";

const navData = jsonData["dataDesktop"];

export function NavList() {
  const navigate = useNavigate();
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false);
  const [isBlogsMenuOpen, setIsBlogsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const { categoriesTree } = useHomeQueryData();

  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);

  const hasHighlight = (pathname: string) => {
    const rootPath = pathname.split("/")[1];
    return (
      location.pathname === pathname ||
      (pathname !== routes.HOME && location.pathname.includes(rootPath))
    );
  };

  const MenuComponent = ({ items }: { items?: CategoriesTree[] }) => {
    const [selectedCategory, setSelectedCategory] = useState<CategoriesTree>();

    return (
      <>
        <ul className="col-span-2 outline-none outline-0 list-none">
          {items?.map((category, index) => {
            return (
              <MenuItemComponent
                item={category}
                key={index}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            );
          })}
        </ul>
        {selectedCategory && selectedCategory?.children.length > 0 && (
          <MenuComponent items={selectedCategory?.children} />
        )}
      </>
    );
  };

  const MenuItemComponent = ({
    item,
    selectedCategory,
    setSelectedCategory,
  }: {
    item: CategoriesTree;
    selectedCategory?: CategoriesTree;
    setSelectedCategory: (category: CategoriesTree) => void;
  }) => {
    return (
      <li
        onMouseEnter={() => {
          setSelectedCategory(item);
        }}
        className={`flex justify-between items-center w-full text-[#000] text-[16px] py-1 relative ${
          selectedCategory?.id === item.id ? "text-[#FF9900] font-bold" : ""
        } cursor-pointer`}
        onClick={() =>
          navigate(`${routes.PRODUCT}?${urls.CATEGORY}=${item.id}`)
        }
      >
        {item.name}
        {!!item.children.length && (
          // <ChevronRightIcon color="#000" className="w-4 h-4 px-[5px] absolute right-2 top-2 rounded-full hover:bg-[#00000020]" />
          <img
            src={images.arrowDown}
            className={`h-[32px] w-[32px] px-[10px] text-[#000] transition-transform -rotate-90 rounded-full hover:bg-[#00000020]`}
          />
        )}
      </li>
    );
  };

  return (
    <div>
      <List
        placeholder={""}
        className="md:flex-row  mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 justify-between gap-8 md:gap-0 w-full"
      >
        {navData.map((i, idx) => (
          <div className="flex items-center gap-2" key={idx}>
            {i?.url === routes.PRODUCT ? (
              <Menu
                allowHover
                open={isProductsMenuOpen}
                handler={setIsProductsMenuOpen}
                offset={{ mainAxis: 10, crossAxis: 280 }}
                placement="bottom"
              >
                <MenuHandler>
                  <div className="font-medium">
                    <MenuItem
                      className={`items-center gap-[16px] flex p-0 py-1 text-[19px] text-[#000] md:text-[16px] font-[400] hover:text-[#FF9900] hover:font-bold ${
                        hasHighlight(i.url) ? "text-[#FF9900] font-[700]" : ""
                      } rounded-none border-[#000000]`}
                      placeholder={""}
                      onClick={() => {
                        navigate(i.url);
                        setIsProductsMenuOpen(false);
                      }}
                    >
                      {i.label}{" "}
                      <img
                        src={images.arrowDown}
                        className={`h-[32px] w-[32px] px-[10px] transition-transform ${
                          isProductsMenuOpen ? "rotate-180" : ""
                        } rounded-full hover:bg-[#00000020]`}
                      />
                    </MenuItem>
                    <MenuList
                      className="w-full rounded-xl border-none shadow-none flex justify-center"
                      placeholder={""}
                    >
                      <div className="grid grid-cols-12 gap-10 outline-none outline-0 max-w-[1120px] w-full">
                        <MenuComponent items={categoriesTree} />
                      </div>
                    </MenuList>
                  </div>
                </MenuHandler>
              </Menu>
            ) : !!i.child.length ? (
              <Menu
                allowHover
                open={i?.label === "Blog" ? isBlogsMenuOpen : isProfileMenuOpen}
                handler={
                  i?.label === "Blog"
                    ? setIsBlogsMenuOpen
                    : setIsProfileMenuOpen
                }
                offset={{ mainAxis: 10, crossAxis: -60 }}
                placement="bottom"
              >
                <MenuHandler>
                  <div className="font-medium">
                    <MenuItem
                      className={`items-center gap-[16px] flex p-0 py-1 text-[#000] font-[400] text-[16px] hover:text-[#FF9900] hover:font-bold ${
                        hasHighlight(i.url) ? "text-[#FF9900] font-[700]" : ""
                      } rounded-none border-[#000000]`}
                      placeholder={""}
                      onClick={() => {
                        if (i.url === routes.PROFILE) {
                          navigate(`${routes.PROFILE}/${routes.PROFILE_INFO}`);
                        } else {
                          navigate(i.url);
                        }
                        setIsBlogsMenuOpen(false);
                      }}
                    >
                      {i.label}
                      <img
                        src={images.arrowDown}
                        className={`h-[32px] w-[32px] px-[10px] text-[#000] transition-transform ${
                          i?.label === "Blog"
                            ? isBlogsMenuOpen && "rotate-180"
                            : isProfileMenuOpen && "rotate-180"
                        } rounded-full hover:bg-[#00000020]`}
                      />
                    </MenuItem>
                    <MenuList
                      className="w-full rounded-xl box-border flex justify-center border-none shadow-none"
                      placeholder={""}
                    >
                      <div className="outline-none outline-0 max-w-[1120px] w-full">
                        <ul className="outline-none outline-0 list-none">
                          {i.child?.map((child, index) => {
                            return (
                              <li
                                key={index}
                                className={`text-[#000] text-[16px] p-2 relative hover:text-[#FF9900] hover:font-bold cursor-pointer antialiased hover:subpixel-antialiased`}
                                onClick={() => navigate(`${i.url}${child.url}`)}
                              >
                                {child.label}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </MenuList>
                  </div>
                </MenuHandler>
              </Menu>
            ) : (
              <div className="font-medium">
                <ListItem
                  placeholder={""}
                  className={`gap-[16px] p-0 py-1 text-[19px] text-[#000] font-[400] md:text-[16px] hover:text-[#FF9900] hover:font-bold ${
                    hasHighlight(i.url) ? "text-[#FF9900] font-[700]" : ""
                  } rounded-none border-[#000000]`}
                  onClick={() => {
                    i.needAuth && !isLoggedIn
                      ? navigate(routes.LOGIN)
                      : navigate(i.url);
                  }}
                >
                  {i.label}
                </ListItem>
              </div>
            )}
          </div>
        ))}
      </List>
    </div>
  );
}
