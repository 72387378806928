import { BaseData } from "@/type/base/baseData";
import { UserAddressType } from "@/utils/AddressType";
import DeliveryItem from "../DeliveryItem";
import { Divider } from "antd";

import svgs from "@/assets/svgs";
import clsx from "clsx";
import Scrollbars from "rc-scrollbars";
import routes from "@/utils/routes";

interface IDeliveryWrapperProps {
  listItem: BaseData<UserAddressType>[];
  handleAddNewAddress: () => void;
  fromPage?: string;
  hasScrollbar?: boolean;
  isCheckout?: boolean;
}

const DeliveryWrapper = (props: IDeliveryWrapperProps) => {
  const {
    listItem,
    handleAddNewAddress,
    fromPage,
    hasScrollbar = true,
    isCheckout = false,
  } = props;

  const isAddressProfile =
    fromPage === `${routes.PROFILE}/${routes.ADDRESS_PROFILE}`;
  return (
    <div
      className={clsx(
        isAddressProfile ? "p-0" : "p-[24px_16px] md:p-0",
        "first-letter:md:p-0 bg-white"
      )}
    >
      {listItem?.length ? (
        hasScrollbar ? (
          <Scrollbars
            style={{ maxWidth: "100vw", height: "498px" }}
            autoHide
            renderTrackVertical={(props) => (
              <div {...props} className="track-vertical z-[9999]" />
            )}
          >
            <div className={clsx("flex flex-col gap-[32px] md:p-0 mr-[15px]")}>
              <div className="bg-white flex flex-col justify-start gap-[16px] md:gap-[24px]">
                {listItem?.map((item, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <div
                        className={clsx(
                          "mt-[10px]",
                          isCheckout ? "hidden" : "block"
                        )}
                      ></div>
                    )}
                    <DeliveryItem
                      item={item}
                      fromPage={fromPage}
                      isCheckout={isCheckout}
                    />
                    {listItem.length - 1 !== index ? (
                      <div
                        className={clsx(
                          "w-full h-[1px] bg-[#B0B0B0] my-[16px] md:my-[24px]",
                          isCheckout ? "hidden" : "block"
                        )}
                      >
                        <Divider className="m-0" />
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          "pb-[10px]",
                          isCheckout ? "hidden" : "block"
                        )}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Scrollbars>
        ) : (
          <div
            className={clsx("flex flex-col gap-[16px] md:gap-[24px] md:p-0")}
          >
            <div className="bg-white flex flex-col gap-[16px]">
              {listItem?.map((item, index) => (
                <div key={index}>
                  {index === 0 && (
                    <div
                      className={clsx(
                        "mt-[10px]",
                        isCheckout ? "hidden" : "block"
                      )}
                    ></div>
                  )}
                  <DeliveryItem
                    item={item}
                    fromPage={fromPage}
                    isCheckout={isCheckout}
                  />
                  {listItem.length - 1 !== index ? (
                    <div
                      className={clsx(
                        "w-full h-[1px] bg-[#B0B0B0] my-[16px] md:my-[24px]",
                        isCheckout ? "hidden" : "block"
                      )}
                    >
                      <Divider className="m-0" />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        "pb-[10px]",
                        isCheckout ? "hidden" : "block"
                      )}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <div className="h-[100px] w-full flex justify-center items-center px-[16px]">
          <p className="text-[14px] text-black text-center">
            Vui lòng thêm địa chỉ giao hàng!
          </p>
        </div>
      )}
      <div className="sticky bottom-0 bg-white flex justify-center items-center gap-[10px] py-[8px] mt-[24px] md:mt-[32px] cursor-pointer">
        <div
          className="flex justify-center items-center gap-[10px]"
          onClick={handleAddNewAddress}
        >
          <div className="w-[16px] h-[16px] md:w-[20px] md:h-[20px]">
            <img src={svgs.addCircleOrange} className="w-full h-full" />
          </div>
          <p className="text-[#FF9900] text-[14px] md:text-[16px] font-[400] leading-[19.6px] md:leading-[22.4px] underline underline-offset-2  md:underline-offset-4">
            Thêm địa chỉ mới
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryWrapper;
