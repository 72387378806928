import i18n from "@/assets/localization/i18n";

const { t } = i18n;

export const formValidation = {
  fullName: { required: t("fullNameNotEmpty") },
  phone: {
    required: t("phoneNotEmpty"),
    pattern: {
      value: /(84|0)+([0-9]{9})\b/g,
      message: t("phoneInvalid"),
    },
  },
  email: {
    required: t("emailNotEmpty"),
    pattern: {
      value: /^[a-zA-Z0-9._-]+@gmail\.com$/,
      message: t("Email không dúng định dạng"),
    },
  },
  birthday: {
    required: t("birthdayNotEmpty"),
    pattern: {
      value: /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/,
      message: t("birthdayInvalid"),
    },
  },
  city: { required: t("cityNotEmpty") },
  district: { required: t("districtNotEmpty") },
  ward: { required: t("wardNotEmpty") },
  password: {
    required: t("passwordNotEmpty"),
    minLength: {
      value: 6,
      message: t("passwordMinLength"),
    },
    maxLength: {
      value: 20,
      message: t("passwordMaxLength"),
    },
  },
  passwordConfirm: (password: string) => {
    return {
      required: t("passwordConfirmNotEmpty"),
      validate: (value: string) => value === password || t("passwordNotMatch"),
      maxLength: {
        value: 20,
        message: t("passwordMaxLength"),
      },
      minLength: {
        value: 6,
        message: t("passwordMinLength"),
      },
    };
  },
  dayOfBirth: { required: t("dayOfBirthNotEmpty") },
  monthOfBirth: { required: t("monthOfBirthNotEmpty") },
  yearOfBirth: { required: t("yearOfBirthNotEmpty") },
  policy: { required: t("policyNotEmpty") },
  identifier: {
    required: t("identifierNotEmpty"),
    validate: (value: string) => {
      const gmailRegex = /^[a-zA-Z0-9._-]+@gmail\.com$/;
      const phoneRegex = /^[0-9]{10,12}$/;
      if (gmailRegex.test(value) || phoneRegex.test(value)) {
        return true;
      }
      return t("Định dạng không đúngs");
    },
  },
  warehouse_address: { required: t("policyNotEmpty") },

  houseNumber: { require: t("numberHouse") },

  house_street: { required: t("houseStreetNotEmpty") },
  address_type: {
    required: t("addressTypeNotEmpty"),
  },
};
