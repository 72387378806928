/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-shadow */
import { formatCurrency } from "@/utils/data";
import { ConfigProvider, Rate } from "antd";
import { useTranslation } from "react-i18next";

interface BaseInfoComponentProps {
  productName: string | undefined;
  productPrice: string | undefined;
  productSalePrice: string | undefined;
  flashSalePrice?: string;
  percent: number;
  // rating mock
  rating: number;
  // don't have field sold number for product
  productPurchase: number;
}

export default function BaseInfoComponent({
  productName,
  productPrice,
  productSalePrice,
  flashSalePrice,
  percent,
  rating,
  productPurchase,
}: BaseInfoComponentProps) {
  const { t } = useTranslation();
  const finalPrice =
    formatCurrency(flashSalePrice ?? "") !== "0"
      ? formatCurrency(flashSalePrice ?? "")
      : formatCurrency(productSalePrice ?? "") !== "0"
      ? formatCurrency(productSalePrice ?? "")
      : formatCurrency(productPrice ?? "");

  return (
    <div className="flex md:flex-col gap-[16px] md:gap-[24px] flex-col-reverse">
      <div className="flex flex-col gap-[8px]">
        <p className="text-[16px] md:text-[18px] font-[400] md:font-[600]">
          {productName}
        </p>

        {/* rating for desktop */}
        <div className="flex justify-start items-center gap-[16px]">
          <div className="flex justify-center items-center gap-[4px]">
            <p className="text text-[11px] md:text-[14px] font-[400] leading-[15.4px] md:leading-[19.6px]">
              {rating || 0}/5
            </p>
            <ConfigProvider
              theme={{
                token: {
                  marginXS: 2,
                },
              }}
            >
              <Rate
                disabled
                allowHalf
                defaultValue={rating || 0}
                className="text-[#FF9900] text-[16px]"
              />
            </ConfigProvider>
          </div>
          <div className="border-r h-[20px] border-[#000]"></div>
          <p className="text-[11px] md:text-[14px]">
            {t("invoiced", { number: productPurchase })}
          </p>
        </div>
      </div>

      <div className="flex items-center md:gap-[10px] gap-[8px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[20px] md:text-[23px] text-[#FF9900] font-[600] md:font-[700]">
            {finalPrice}đ
          </p>
          {productSalePrice !== productPrice && (
            <p className="text-[14px] font-[400] leading-[19.6px] text-[#ABABAB] line-through">
              {formatCurrency(productSalePrice ?? "") !== "0" &&
                formatCurrency(productPrice ?? "") + "đ"}
            </p>
          )}
        </div>
        {percent > 0 && (
          <div className="p-[2px_8px] bg-[#F8F2E2] rounded-[16px]">
            <p className="text-center text-[12px] font-[500] leading-[16.8px] tracking-[-0.12px] text-[#FF9900]">
              {`-${percent}%`}
            </p>
          </div>
        )}
      </div>
      {/* rating for mobile */}
      {/* <div className="product-detail__rating flex justify-start items-center text-primary-ciment md:hidden mb-2">
        <p className="text mr-1 text-base">{rating}/5</p>
        <Rate disabled defaultValue={rating} />

        <p className="text-sm ml-2">{t('invoiced', { number: 10 })}</p>
      </div> */}
    </div>
  );
}
