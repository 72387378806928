import orderApi from "@/api/orderApi";
import { useQuery } from "@tanstack/react-query";

export default function useQueryReasonCancel(openTime?: string) {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["cancelReasons", openTime],
    queryFn: () => orderApi.getCancelReasons(),
    enabled: !!openTime,
  });

  return {
    data: data?.data.data || [],
    isLoading,
    isFetching,
  };
}
