export const typeValueAddress: Record<number, string> = {
  1: "Mặc định",
  2: "Nhà riêng",
  3: "Công ty",
};

type AddressCommonType = {
  id: number;
  name: string;
};
export type ProvinceCityType = AddressCommonType;

export type DistrictType = AddressCommonType;

export type WardType = {
  code: string | null;
  name?: string;
};

export type AddressType = {
  provinceCity: ProvinceCityType;
  district: DistrictType;
  ward: WardType;
  street: string;
  houseNumber: string;
};

export type UserAddressType = {
  fullName: string;
  phone: string;
  email: string;
  type: string;
  address: AddressType;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  isDefault: boolean;
};
