import { ICartProduct } from "@/recoil/cartState";

// Hàm kiểm tra xem thời gian hiện tại có nằm trong khoảng khuyến mãi không
const isWithinFlashSaleTime = (startTime: string, endTime: string): boolean => {
  const currentTime = new Date();
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  const start = new Date();
  start.setHours(startHours, startMinutes);

  const end = new Date();
  end.setHours(endHours, endMinutes);

  return currentTime >= start && currentTime <= end;
};

// Hàm cập nhật giỏ hàng với flash sale
export const updateCartWithFlashSale = (
  flashSale: any,
  productsFlashSale: any[]
) => {
  const cartProducts: ICartProduct[] = JSON.parse(
    localStorage.getItem("cart") || "[]"
  );

  if (!flashSale || productsFlashSale.length === 0) {
    const updatedCart: ICartProduct[] = cartProducts.map((product) => {

      const updatedProduct = {
        id: product?.id,
        quantity: product?.quantity,
        data: {
          ...product.data,
          flashSale: false,
          flashSalePrice: product?.data?.pricePromotion || product?.data?.price,
        }
      }

      return updatedProduct;
    });


    localStorage.setItem("cart", JSON.stringify(updatedCart));
    return updatedCart;
  }

  const { startTime, endTime } = flashSale?.attributes;

  const updatedCart: ICartProduct[] = cartProducts.map((product) => {
    const flashSaleProduct = productsFlashSale.find(
      (flashSaleItem) => flashSaleItem?.id === product?.data?.id
    );

    if (flashSaleProduct && isWithinFlashSaleTime(startTime, endTime)) {
      const updatedProduct = {
        id: product?.id,
        quantity: product?.quantity,
        data: {
          ...product.data,
          flashSale: true,
          flashSalePrice:
            flashSaleProduct?.attributes?.flashSalePrice ||
            product?.data?.price,
        },
      };

      return updatedProduct
    } else {
      const updatedProduct = {
        id: product?.id,
        quantity: product?.quantity,
        data: {
          ...product.data,
          flashSale: false,
          flashSalePrice: product?.data?.pricePromotion || product?.data?.price,
        },
      };

      return updatedProduct
    }
  });

  localStorage.setItem("cart", JSON.stringify(updatedCart));

  return updatedCart;
};
