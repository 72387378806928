import productApi from "@/api/productApi";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import { useQuery } from "@tanstack/react-query";
import { useFlashSaleQueryData } from "./home.flashSale.query";

const MINUTES_TIME_STALE = 10;

export default function useProductsFilterQuery(
  searchParams?: string,
  isFilterFlashSale = false
) {
  if (isFilterFlashSale) {
    const {
      flashSale,
      productsFlashSale,
      isLoading,
      isFetching,
      error,
      isError,
    } = useFlashSaleQueryData();

    return {
      flashSale,
      productsFlashSale,
      isLoading,
      isFetching,
      error,
      isError,
    };
  }

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["products", searchParams],
    queryFn: () => productApi.getAll(searchParams),
    staleTime: 1000 * 60 * MINUTES_TIME_STALE,
  });

  const products: BaseData<ProductsResponse>[] = data?.data?.data || [];
  const informationPagination = data?.data?.meta?.pagination;
  return { informationPagination, data, products, isLoading, isFetched };
}
